:root {
  --title-bg_color: rgb(150 106 77/1);
  --white: #ffffff;
  /* --table_h_color:rgb(150 106 77); */
  --border-color: #dee2e6;
  /* --bg-color:#f7f3f1; */
  --main-bg-color:rgb(150 106 77/1);
  --title-color: rgb(150 106 77);
  --text-color: rgb(0 0 0 / 80%);
  --input-sel-color:rgb(0 0 0 / 50%);
  --input_label_color:#282829;
}
.headerlogo {
    background: rgb(150 106 77/1);
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
}

ul {
    padding-left: 15px;
}

.theme_btn {
    color: #fff;
    background-color: #9A6C4B !important;
    border-color: #9A6C4B !important;
}

a:hover {
    text-decoration: none;
}

.headerlogo .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerlogo {
    background: rgb(150 106 77/1);
    padding: 35px 0;
}

.headerlogo a.button {
    padding: 15px 22px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
}

.headerlogo a.button {
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
}

button,
.registercss a {
    border: 1px solid var(--title-color);
    background: var(--title-bg_color);
    color: #fff !important;
    /* width: auto !important; */
    border-radius: 15px !important;
    padding: 10px 25px;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.9s;
}

.registercss a:hover {
    background: #fff;
    color: rgb(150 106 77) !important;
}

.registercss a:hover img {
    filter: brightness(0.6);
}

.button:hover {
    text-decoration: none;
}

.headerlogo img {
    max-width: 270px;
}

.main_Box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #D8D8D8;
}

.left_nav ul {
    padding-left: 0;
}

.left_nav li {
    list-style: none;
}

.nav_logo {
    width: 85px;
    background: #D8D8D8;
    height: 85px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.nav_logo span {
    color: #fff;
    font-size: 44px;
    font-weight: 500;
}

/* .nav_logo span {
    font-size: 27px;
    font-weight: 700;
    background: #fff;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
} */
.nav_logo_main {
    margin-top: 30px;
    text-align: center;
}

.nav_logo_main a {
    font-size: 20px;
    margin-top: 14px;
    display: inline-block;
    /* padding: 4px 40px; */
    font-weight: 600;
}

/* .nav_logo_main a:hover {
    background: black;
    color: #fff !important;
} */
li.nav_item a {
    font-size: 22px;
    padding: 10px;
    width: 100%;
    display: inline-block;
    margin: 6px 0;
    background-color: #D8D8D8;
    border-radius: 8px;
    position: relative;
}

.nav_drop a:after {
    width: 85%;
    height: 1px;
    content: "";
    position: absolute;
    background: #D8D8D8;
    left: -25px;
    bottom: -7px;
    transition: 1s;
}

.nav_drop .is_active a:after {
    left: -10px;
    width: 100%;
    transition: 1s;
}

.nav_drop li {
    padding-left: 10px;
}

li.nav_item a:hover {
    text-decoration: none;
}

.left_nav {
    background: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 290px;
}

li.nav_item>a:hover {
    background: rgb(150 106 77/1);
    color: #fff !important;
}

.nav_drop a:hover {
    background: rgb(150 106 77/1);
    color: #fff !important;
}

.nav_drop a {
    font-size: 18px !important;
}

.logout a {
    font-size: 22px;
    padding: 10px;
    width: 100%;
    display: inline-block;
    background: #D8D8D8;
    text-align: center;
    text-transform: uppercase;
    border-radius: 8px;
}

.right_content {
    width: calc(100% - 290px);
}

.main_Box {
    height: calc(100vh - 137px);
}

.right_content .form {
    max-width: 950px;
    margin: 0 0 0 50px;
    border-radius: 25px;
    box-shadow: none;
}

.right_content {
    max-width: 100%;
    overflow-y: auto !important;
    max-height: calc(100vh - 150px);
    overflow: hidden;
    padding-top: 50px;
}

.right_content>div>nav>ul {
    margin-bottom: 0;
}

/* #app {
    height: 100%!important;
    position: fixed;
    width: 100%;
    overflow: hidden;
} */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgb(153, 153, 153);
}

::-webkit-scrollbar-thumb {
    background: rgb(95, 95, 95);
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.welcome_header {
    display: flex;
    background: rgb(150 106 77/1);
    padding: 35px 0;
    justify-content: center;
}

.toggle_menu {
    filter: invert(1);
    width: 35px;
    margin-left: 20px;
    cursor: pointer;
    display: none;
}

.footer_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.containers .checkmark {
    border: 1px solid #7A7979;
}

.containers .checkmark:after {
    left: 9px;
    border-width: 0 2px 2px 0;
    top: 5px;
}

button#select_flag_button {
    color: #7A7979 !important;
}

input,
select,
.ant-input,
input[type="text"] {
    border-bottom: 1px solid var(--border-color);
    font-size: 14px;
    color: rgb(150 106 77/1)000 !important;
    font-weight: 400;
}

select,
::placeholder,
button#select_flag_button {
   
    color: var(--input-sel-color) !important;
    font-size: 16px;
    font-weight: 400 !important;
}

.wel__come input,
.wel__come select,
.wel__come .ant-input {
    background: #fff;
}

h3.form_heading {
    padding-left: 50px;
    margin-bottom: 20px;
}

button.ant-switch,
button.ant-switch:hover {
    background: black;
}

.mobile_num .fortyyy input {
    padding-left: 50px !important;
}

.num_btn {
    background: transparent;
    color: rgb(150 106 77/1) !important;
    border-radius: 10px !important;
    border: 1px solid;
}

.wel__come {
    max-width: 800px;
    margin: auto;
    background: #F7F3F0 !important;
}

.welcome_content {
    padding: 40px;
}

.wel__come .footer {
    display: flex;
    justify-content: end;
}

.wel__come .footer-pn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wel__come h3 {
    font-size: 26px;
}

li.nav_item>a {
    padding-left: 15px;
}

.nav_drop a:before {
    content: ">";
    padding-right: 8px;
}

.check_YN {
    border: 1px solid rgb(150 106 77);
    padding: 2px;
    border-radius: 30px;
}

.check_YN input {
    margin: 0 !important;
}

.check_YN input {
    /* background: #F7F3F0; */
    padding: 5px 10px !important;
}

.check_YN .input {
    color: rgb(150 106 77/1) !important;
    background: transparent !important;
}

.check_YN input.actives {
    background: rgb(150 106 77/1) !important;
    color: #fff !important;
}

/* .check_YN.check_b input.toogles.active.btn.btn-primary {
    background: transparent !important;
    color: rgb(150 106 77/1) !important;
} */
.check_YN.check_b input.toogles.active.btn.btn-primary.actives {
    background: rgb(150 106 77/1) !important;
    color: #fff !important;
}

.border_BTN button {
    background: transparent;
    color: rgb(150 106 77/1) !important;
    max-height: 43px;
}

/* .border_BTN {
    // background: transparent; 
    color: rgb(150 106 77/1) !important;
    max-height: 43px;
    font-weight: 700 !important;
    margin-left: auto;
} */
.border_BTN:hover{
    color: var(--title-color) !important;
    border: 1px solid var(--title-color);
}

.border_BTN {
    color: #fff !important;
    max-height: 43px;
    font-weight: 700 !important;
    margin-left: auto;
}
.Other .mouse {
    border: 1px solid #b4b4b4;
}
.date_grey {
    color: grey !important;
  }
  .date_dark {
    color: rgb(150 106 77/1) !important;
  }

.form__width {
    width: 50%;
}

.date-w .ui.icon.input>input {
    width: 50%;
    border-bottom: 1px solid var(--border-color) !important;
    background: transparent !important;
}

.date-w .field {
    width: 100%;
}

.button_group.irdspace {
    margin-left: 0;
}

/* .input__w50.flag-select.counrtyselext,
.input__w50.flag-select.counrtyselext2,
.input__w50,
.ui.icon.input.input__w50_a input {
    border-bottom: 1px solid rgb(150 106 77) !important;
    width: 50%;
    background: transparent !important;
    padding-bottom: 6px !important;
} */
.six_page .field {
    width: 100%;
}

.check_YN_Bank {
    display: flex;
    border: 1px solid rgb(150 106 77);
    padding: 3px;
    border-radius: 15px;
}

.check_YN_Bank label {
    margin-bottom: 0 !important;
    border-radius: 29px !important;
    padding: 4px 8px !important;
    line-height: 19px;
    font-size: 14px;
    font-weight: 500;
    display: flex !important;
    align-items: center;
}

.check_YN_Bank input:checked+label {
    background-color: rgb(150 106 77/1) !important;
    color: #fff;
}

.check_YN_Bank .actives {
    background: rgb(150 106 77/1) !important;
    color: #fff !important;
}

.check_YN_Bank label {
    background-color: transparent !important;
    color: var(--text-color);
}
.swtich233 {
    width: 243px !important;
    height: 58px !important;
    margin-top: 10px;
}
.Shareholder.col-sm-12 {
    padding: 23px 15px;
}
.check-mb1.row {
    padding: 13px 0px;
}

.welcome-header img {
    max-width: 240px;
}

.welcome-header {
    background: rgb(150 106 77/1);
    /* padding: 0 0 18px;  */
    display: flex;
    justify-content: center;
}

.wel-come_content {
    padding: 40px;
}
.main_reg_page{
    background-image: url(./pages/home-hero.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 40px 10px;
    min-height: 100vh;
    background-attachment: fixed;
}


.swal2-container:has(>.bg_colory) {
    background:url(./pages/home-hero.jpg)!important;
    /* background-image: url(./pages/home-hero.jpg); */
}

.welcome__page {
    max-width: 700px;
    margin: 30px auto 30px;
    /* box-shadow: 0 0 13px 4px #e8e8e8; */
    box-shadow: unset;
    background-color: #fff;
}
 

.fullinputNumber .numberSet {
    width: 95px;
}

.fullinputNumber .input {
    width: 100%;
    padding-right: 0 !important;
}

.fullinputNumber .numberSets {
    /* width: calc(100% - 95px); */
}

.spceimg.ml-50 input#removeImage2 {
    padding: 0 6px !important;
}

.spceimg input#removeImage2 {
    padding: 0 6px !important;
}

ul.usual li {
    list-style: decimal;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* .btn_upload img {
    filter: grayscale(1);
} */

.check_box_p label {
    font-size: 13px;
}



.clndr-days .clndr-cell {
    background-color: #fff;
    color: rgb(150 106 77/1) !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clndr-days .clndr-cell-disabled {
    cursor: default;
    background: #e1e0e0 !important;
    color: rgb(150 106 77/1) !important;
}

.clndr-days {
    background: #959595 !important;
}

.check_first input {
    margin-right: 10px;
}

input[type="checkbox"] {
    width: 22px;
    height: 22px;
}

.checkmystyle .checkmark:after {
    left: 10px;
    top: 7px;
}

.checkmystyle input:checked~.checkmark {
    background-color: rgb(150 106 77/1);
}

.review_b {
    display: flex;
    text-transform: capitalize; 
}

.review_b label{
    width: 50%;
    text-transform: none;
    font-weight: 500;
    line-height: 24px;
    margin-top: 0;
}
.review_b p {
    width: 50%;
    /* text-transform: none; */
    font-weight: 500;
    line-height: 24px;
}

.review h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

/* .review h2 {
    font-size: 20px;
    background: rgb(150 106 77);
    padding: 15px 50px;
    margin: -48px -48px 0 -48px;
    border-radius: 22px 22px 0 0;
    margin-bottom: 20px;
    color: #fff !important;
} */
.padding-left2 label {
    padding-left: 20px;
}

img.imageCover {
    display: none;
}

.grid-container {
    display: none;
}

.header_TP img {
    max-width: 200px;
    /* margin-top: -17px; */
}
/* .bg_bacv{
   background:url(./pages/home-hero.jpg)!important;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   padding: 40px 10px;
 
   background-attachment: fixed;
} */

@media screen and (max-width: 1300px) {
    .right_content .form {
        max-width: calc(100% - 70px);
    }
}

@media screen and (max-width: 991px) {
    body.is_active:before {
        background: rgb(150 106 77/1);
        width: 100%;
        content: "";
        top: 0;
        left: 0;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .left_nav {
        position: fixed;
        top: 137px;
        left: -300px;
        z-index: 999;
        transition: 0.4s;
        height: calc(100vh - 137px);
    }

    .is_active .left_nav {
        transition: 0.4s;
        left: 0;
    }

    .toggle_menu {
        display: inline-block;
    }

    .right_content {
        margin-top: 30px;
    }

    .right_content .form {
        max-width: calc(100% - 15px);
        padding: 30px 20px 20px 20px;
    }

    .right_content {
        width: 100%;
    }

    .right_content .form {
        margin: auto;
    }

    #app {
        height: 100% !important;
        position: relative;
        width: 100%;
        overflow: visible;
    }

    .right_content {
        max-width: 100%;
        overflow-y: visible !important;
        max-height: initial;
        overflow: visible;
    }

    .headerlogo {
        position: fixed;
        width: 100%;
        z-index: 9999;
    }

    .ui.icon.input>input {
        width: 100%;
    }

    .button_group_citi input,
    .button_group_citi label {
        width: 100%;
    }

    .button_group_citi .switch-field input {
        position: relative !important;
    }

    .right_content {
        margin-top: 170px;
    }
}
@media screen and (min-width:768px) {
    .yes1 .click-hover {
        padding: 10px;
        font-size: 13px;
    }
    
}
@media screen and (max-width: 767px) {
    .headerlogo img {
        max-width: 200px;
    }
    .attach-styhle-proof{
        margin-left: 5px;
        margin-top: 0;
    }
    .select_num_add .numberSet{
        max-width: 95px !important;
    }
    .grid-item {
        width: 35px;
    }
    .left_nav {
        top: 119px;
        height: calc(100vh - 119px);
    }

    .right_content {
        margin-top: 150px;
    }

    input, select, textarea {
        width: 100%;
    }

    div.FlagsSelectcss button {
        width: 100% !important;
    }

    .date-w .ui.icon.input>input {
        width: 100%;
    }
    /* jitendra css */
    span.ant-input-suffix{
        right: 15px !important;
      }
    /* jitendra css */

}

@media screen and (max-width: 479px) {
    .header_TP img {
        max-width: 165px;
    }

    .header_TP .button.bg-white {
        padding: 6px 10px !important;
        font-size: 15px !important;
    }

    .headerlogo .button {
        padding: 10px 15px;
        border-radius: 4px;
        font-size: 16px;
    }

    .headerlogo img {
        max-width: 140px;
    }

    .toggle_menu {
        width: 25px;
        margin-left: 10px;
    }

    .grid-item {
        width: 22px;
    }

    .left_nav {
        top: 105px;
        height: calc(100vh - 105px);
    }

    .right_content {
        margin-top: 130px;
    }

    .welcome_content {
        padding: 40px 15px;
    }
    /* jitendra css */
    .registered{
        padding: 15px;
    }
    
    /* jitendra css */

}

.nav_drop .is_active a {
    background-color: rgb(150 106 77/1);
    color: #fff !important;
}

/*
mohan
 */
.react-tel-input input {
    padding-left: 45px !important;
}

/* .save-exit {
    display: none;
} */






.ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 100% !important;
    color: rgb(150 106 77/1) !important;
    border: none;
}

.btn-box.border_BTN {
    display: flex;
}

.form h3 {
    display: flex;
    align-items: baseline;
}

.clndr-control {
    background: #fff;
    margin-bottom: 0 !important;
}

.clndr-control>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signature_h {
    height: 180px;
}

.FlagsSelectcss button {
    width: 50% !important;
    border: none;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0 !important;
}
.FlagsSelectcss1 button {
    width: 50% !important;
    border: none;
    border-bottom: 1px solid rgb(150 106 77);
    border-radius: 0 !important;
}

.FlagsSelectcss ul {
    width: 50%;
}

.FlagsSelectcss {
    margin-bottom: 15px;
}

.FlagsSelectcss button span {
    font-weight: 400;
    color: var(--text-color) !important;
}

.FlagsSelectcss ul {
    margin-top: 0;
}



.header_TP {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 110px;
}

.header_TP .button.bg-white {
    border-radius: 5px;
    padding: 12px 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    color: rgb(150 106 77) !important;
    min-height: 47px;
}

.left__nav__main>div>div>div {
    top: 110px;
    padding: 0 20px;
}

.Header__main {
    width: 100%;
    background: var(--main-bg-color);
}



.css-1jliix {
    margin-bottom: 20px;
}

/* .left__nav__main a,
.left__nav__main a.active {
    padding: 10px;
    display: flex;
} */



.css-16dqz1z-MuiButtonBase-root-MuiListItemButton-root:before {
    display: none;
}

/* .ul.MuiList-root.css-1mk9mw3-MuiList-root {
    margin-top: 30px;
    text-align: center;
}
.ul.MuiList-root.css-1mk9mw3-MuiList-root a {
    font-size: 20px;
    margin-top: 14px;
    display: inline-block;
    padding: 4px 40px;
    font-weight: 600;
}
.ul.MuiList-root.css-1mk9mw3-MuiList-root a:hover {
    background: black;
    color: #fff !important;
} */
.left__nav__main ul a {
    font-size: 22px;
    padding: 10px;
    width: 100%;
    display: flex;
    margin: 6px 0;
    background-color: #D8D8D8;
    border-radius: 8px;
    position: relative;
    font-weight: 400;
}

.left__nav__main ul a:before {
    display: none !important;
}

.left__nav__main ul a:hover {
    background: rgb(150 106 77);
    color: #fff !important;
}

.nav_drop a:after {
    width: 85%;
    height: 1px;
    content: "";
    position: absolute;
    background: #D8D8D8;
    left: -25px;
    bottom: -7px;
    transition: 1s;
}

.nav_drop .is_active a:after {
    left: -10px;
    width: 100%;
    transition: 1s;
}


.left__nav__main a.active {
    background: rgb(150 106 77/1);
    color: #fff !important;
}

.left__nav__main .MuiListItemIcon-root {
    margin-right: 0;
    width: 30px;
    height: 30px;
}

.left__nav__main svg {
    width: 30px;
    height: 30px;
}

.register_inputP input,
.register_inputP select {
    width: 100%;
}

.check_box_p.left {
    margin-bottom: 30px;
}

.check_box_p .checkmark:after {
    left: 8px;
    border-width: 0 2px 2px 0;
    top: 4px;
    width: 4.5px;
    height: 9px;
}

.check_box_p .checkmark {
    height: 22px;
    width: 22px;
}

i.angle.right.icon {
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
    width: 20px;
    height: 19px;
    transform: rotate(-90deg);
    background-position: center;
    border-radius: 43px !important;
    background-repeat: no-repeat;
}

i.angle.double.left.icon,
i.angle.left.icon {
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
    width: 20px;
    height: 19px;
    background-position: center;
    border-radius: 43px !important;
    background-repeat: no-repeat;
    transform: rotate(90deg);
}

button.ui.basic.compact.icon.disabled.button,
button.ui.basic.compact.icon.button {
    margin: 0 1px !important;
}

button.ui.basic.compact.icon.button {
    padding: 0;
}

.clndr-control button i {
    background-size: 16px;
}

.clndr-control button:hover {
    background: rgb(150 106 77/1);
}

.review {
    margin-bottom: 20px;
}

.review_b p {
    padding-left: 20px;
    margin-bottom: 8px;
    font-weight: 400;
}

.second a.hyperLink1 {
    text-decoration: underline;
    margin-left: 0;
    color: rgb(150 106 77) !important;
}

.FlagsSelectcss button span {
    padding: 0;
    margin: 0;
    font-size: 16px;

}

.FlagsSelectcss button svg {
    margin-right: 8px;
}

select,
.FlagsSelectcss button {
    background-position: center right 10px;
    background-image: url("././pages/select-down.png");
    background-repeat: no-repeat;
    background-size: 10px;
    padding-right: 25px !important;
}

.FlagsSelectcss button:after,
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    display: none !important;
}

.display.linkk.sec {
    margin-bottom: 15px;
    display: block;
}


.register_inputP select:valid {
    color: rgb(150 106 77) !important;
}


.ppp {
    color: rgb(150 106 77) !important;
}

.FlagsSelectcss button {
    text-transform: inherit !important;
}



input#date {
    text-transform: lowercase;
}

.input__w50_a input {
    text-transform: lowercase;
}

.date-w .ui input::placeholder {
    text-transform: lowercase !important;
}


.joint_check_btn {
    width: 92px;
    height: 35px;
    border: 1px solid rgb(150 106 77);
    padding: 2px;
    border-radius: 30px;
}

span.ant-input-suffix {
    position: absolute;
    top: 0;
    right: 51%;
}

.check_box_wel {
    margin-bottom: 30px;
    margin-top: 4px;
}

.check_box_wel span.ant-checkbox-inner {
    border: 1px solid var(--title-bg_color);
    height: 22px;
    width: 22px;
    position: absolute;
}

.check_box_wel input:checked~.ant-checkbox-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: rgb(150 106 77/1)000;
    border-radius: 0;
}

.check_box_wel .ant-checkbox {
    position: absolute;
    left: 0;
}

.ant-checkbox-inner:after {
    content: "";
    position: absolute;
    display: none;
}

.check_box_wel input {
    position: absolute;
    margin-bottom: 0;
    opacity: 0;
}

.check_box_wel .ant-checkbox-wrapper {
    position: relative;
    padding-left: 30px;
}

.check_box_wel .ant-checkbox-wrapper span {
    font-size: 14px;
}

.check_box_wel input:checked~.ant-checkbox-inner:after {
    display: block;
}

.check_box_wel .ant-checkbox-inner:after {
    left: 8px;
    top: 4px;
    width: 4.5px;
    height: 9px;
    left: 9px;
    border: solid #966a4d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.wel-come_content .ant-form-item-control,
.wel-come_content .ant-form-item {
    position: relative;
}

.wel-come_content .ant-form-item-explain.ant-form-item-explain-connected {
    position: relative;
    margin-top: -15px;
    margin-bottom: 20px;
    color: red;
}

.MuiBox-root.css-1jliix button {
    width: 100% !important;
    justify-content: center;
}

label.swal2-checkbox {
    margin-bottom: 0;
}

label.swal2-checkbox input {
    display: none;
}

/* --------------14-01-22------------- */
.clndr-calendars-wrapper div .clndr-days {
    width: 341px;
    box-shadow: 6px 6px 20px -10px rgb(0 0 0 / 75%);
}

.clndr-control span {
    font-weight: 600;
    color: rgb(150 106 77);
}

.FlagsSelectcss button span>span {
    color: var(--text-color) !important;
    font-weight: 400;
}

.welcome__page .ant-form-item-has-success select {
    color: var(--text-color) !important;
}

.nrdt_chack input {
    z-index: 1;
    left: 0;
    height: 25px;
    width: 25px;
}

/* img.info {
    filter: invert(1);
} */

.save-exit p {
    color: rgb(150 106 77);
    top: 10.7px;
    font-size: 14px;
}

/* .save-exit {
    position: absolute;
    width: 110px;
    top: -61px;
    right: 73px;
} */

.left__nav__main .simplebar-content {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 110px) !important;
}

.left__nav__main .simplebar-content:before,
.left__nav__main .simplebar-content:after {
    display: none;
}


.nrdt_chack .checkmystyle {
    /* padding-top: 2px; */
}

/* .select_num_add .numberSets {
    width: calc(100% - 180px);
} */

.select_num_add .numberSet {
    width: 100%;
    max-width: 103px;
}

.select_num_add select {
    max-width: 112px;
    margin-right: 6px;
}
.swtich10 {
    width: 100%;
    height: 45px;
}
.swtich10 label {font-size: 13px !important;line-height: 16px;}
.swtich11 {
    width: 122.19px;
    height: 45px;
}
.swtich11 label {
    padding: 0px 17px !important;
}
.errercolor{
    bottom: 10px;
    right: 30px;
    float: right;
    
}
.Dates{
padding-left: 25px !important;
}
.accountJoint.col-4 {
    margin-bottom: 10px;
}
.person{
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;

}
/* .fundesss{
    width: 50%;

} */
.msg-show{
    color: gray;
}
.Divinvestor {
    display: flex;
    flex-direction: row;
}
p.certifies__Input.newdivadd {
 
    margin: 10px;
}
label.Investor {
    align-self: center;
}
.remove_space1 {
    margin-bottom: 0;
}
.colorgreys{
    color:grey
}
.colorblacks{
    color: rgb(150 106 77/1);
}
 
li.order-list-item::before {
    /* color: red; */
    font-weight: 900;
}

.order-list-item ~ li::before {
    /* color: red; */
    font-weight: 900;
}
.bigblue{
    color:gray !important;
}
.bigblues{
    color: rgb(150 106 77/1) !important;
}
.term-SignedNew {
    width: 13.3rem;
  }
.term-entityNew {
    padding: 5px 10px !important;
}
.errorroll{
   margin-bottom: 5px;
}
.Trust_ind_btn {
    float: right;
}
.quantitys{
    width: 55% !important;
    padding-left: 2px !important;
}
.numberss{
    width: 45% !important;
    padding-left: 2px !important;
}
.postal_address {
    padding-left: 2px !important;

}
.numbers{
    padding-left: 2px !important;
}
input.ird_number {
    width: 50%;
    margin-left: 2px !important;
}
.revove_btn{
    padding-bottom: 10px;
}
.menuCompanyName {
    font-weight: bold;
    margin: auto;
    display: table;
}
/* .bdetail01box {
    margin-bottom: 45px;
    padding: 25px;
    outline: groove;
   
} */


.ggg {
    /* outline: groove; */
    margin-bottom: 10px;
    padding: 20px;
     border: 2px solid #ccc;
    
}
#jjjj{
    margin-bottom: 10px;
    padding: 20px;
     border: 2px solid #ccc;
}


.Select_role{
    margin-bottom: 20px;
}
.removebt0{
    display: none;
}

.attach-style-proof label .myinfo.trr {
    right: 18px!important;
}
.contact_us{
    font-size: 17px;
}
label.bankdClick .info {
    position: relative!important;
    left: -15px;
    right: 0;
    float: inherit;
    float: right;
    top: 4px;
}
/* label.bankdClick {
    max-width: 285px;
}  */
.InputMask-number {
    width: 214px;
}
input.numbers.setinput {
    width: 194px !important;
}  
.userEmail{
    text-transform: lowercase;
}