@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
  color: var(--input_label_color) !important;
  /* background: #d5d5d5; */
  height: 100vh;
}
:root {
  --title-bg_color: rgb(150 106 77/1);
  --white: #ffffff;
  /* --table_h_color:rgb(150 106 77); */
  --border-color: #dee2e6;
  /* --bg-color:#f7f3f1; */
  --title-color: rgb(150 106 77);
  --text-color: rgb(0 0 0 / 80%);
  --input-sel-color:rgb(0 0 0 / 50%);
  --input_label_color:#282829;

}

* {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: 'Roboto', sans-serif !important; */
  box-sizing: content-box;
  font-size: 1rem;
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color) !important;
  font-family: 'Roboto', sans-serif !important;
}

input,
select {
  text-align: left;
  /* padding: 0.3rem 0.4rem; */
  margin: 0.5rem 0 0.5rem 0.8rem;
  border: 1px solid var(--border-color);
  color: #a2a2a2;
  width: 50%;
  outline: none;
}

*,
:after,
:before {
  box-sizing: border-box !important;
}

.form select {
  background-color: transparent;
  /* padding: 0.3rem 0.8rem; */
  /* margin: 0.5rem 0 0.5rem 0.8rem; */
  color: var(--text-color) !important;
  height: 42px;
  /* background: #f7f3f0; */
  /* border: none; */
  margin-left: 0;
}

/* button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  margin: 0.5rem 0;
} */

.imageCover {
  max-width: 112px;
  height: auto;
  margin-bottom: 0;
  margin: 0 auto 40px;
  display: block;
}

/* body {
  background: #fff !important;
} */
.form {
  max-width: 800px;
  margin: 20px 0 30px 30px;
  /* width: 100%; */
  align-items: center;
  background: #ffff;
  /* box-shadow: 2px 3px 13px 4px #e8e8e8; */
  border-radius: 10px;
  padding: 45px;
  /* padding: 20px; */
  position: initial;
  border: 1px solid var(--border-color);
}

input,
select,
textarea {
  text-align: left;
  /* padding: 5px 10px !important; */
  margin: 0;
  color: var(--text-color);
  display: block;
  /* background: #f7f3f0; */
  border: none;
  width: 50%;
  margin-bottom: 20px !important;
}

.fullinput input {
  width: 100%;
}

.fotter-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.second {
  justify-content: flex-end;
}

h3 {
  font-size: 25px;
  font-family: auto;
  font-weight: 400;
}

h6 {
  font-size: 14px;
}

label {
  color: var(--input_label_color);
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif !important;
  /* text-transform: uppercase; */
}

.form.print {
  padding: 25px;
}

input:placeholder {
  font-size: 20px;
}

.form.print div {
  font-size: 15px;
  color: #343434;
}

.form.print button {
  float: right;
  border: 2px solid var(--border-color);
  width: 82px;
  text-align: center;
  font-size: 14px;
}

/* button {
  border: 2px solid var(--border-color);
  width: 82px;
  text-align: center;
} */
button {
  border: 2px solid var(--border-color);
  width: 82px;
  text-align: center;
  background: var(--title-bg_color);
  color: #fff !important;
  width: auto !important;
  border-radius: 26px !important;
  padding: 9px 25px;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}

/* .next {
  font-size: 14px;
  font-weight: 600;
  color: #b5c9d2;
} */
/* .preview {
  font-size: 14px;
  font-weight: 600;
  color: #b5c9d2;
} */
.login {
  width: 410px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 55px 63px 55px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

input[type='text'] {
  /* padding-left: 7px; */
  font-size: 15px;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.swal2-styled.swal2-cancel:focus{
  box-shadow: none !important;
}
.login label {
  font-weight: 400;
}

input.button-login {
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
  background: var(--table_h_color);
  text-align: center;
  width: 252px;
  margin-bottom: 32px;
  color: #fff;
}

a.sign {
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #666666;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto;
  display: block;
  text-decoration: none;
}

.login input {
  width: 100%;
  background: transparent;
  border-radius: 0;
  border-bottom: 2px solid var(--border-color);
  margin-bottom: 37px;
}

.login img {
  margin-bottom: 70px;
}

input.button-login {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
  background: #9b6f52;
  width: 232px;
  margin-bottom: 52px;
  margin-top: 71px;
}

fieldset#step2 {
  width: 40%;
  margin: 22px auto;
  border-radius: 7px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.btn-name {
  width: 100%;
  height: 30px;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.button-lg a {
  text-align: justify;
  color: #343534;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  margin: 0 auto;
  display: block;
  position: relative;
  left: 40%;
  word-spacing: 2px;
}

h5.appli {
  margin: 46px auto 44px;
  font-size: 19px;
  font-family: inherit;
  text-align: center;
}

#step2 i.fas.fa-circle {
  color: #f6f6f6;
  margin: 0px 0px 0px 0px;
  font-size: 29px;
  position: absolute;
  left: -41px;
  top: -4px;
  background: #f7f3f0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

button.next.con {
  border: 1px solid var(--border-color);
  background-color: #ffffff;
  padding: 8px 30px;
  margin: 50px 0px;
  display: block;
}

#step2 hr {
  width: 282px;
  margin: 15px auto 30px;
  border-color: #f0f4f5;
}

button {
  cursor: pointer;
}

.btn-name:hover .fas {
  background: #9b6f52 !important;
}

.greytext {
  color: dimgrey;
  font-weight: 200;
  line-height: 27px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.second h6 {
  margin-bottom: 6px;
  margin-top: 0px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
}

.grid-item {
  border-bottom: 1px solid var(--border-color);
  margin: 4px;
  text-align: center;
  margin-bottom: 22px;
}

.grid-item.active {
  border-bottom: 2px solid var(--border-color);
}

.form.print button.submit {
  float: none;
  margin: 50px auto;
  display: block;
}

img.thumb {
  width: 100%;
  max-width: 36%;
}

img.thumb {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.thnku {
  margin: 0 auto;
  display: block;
  text-align: center;
  background: #ffff;
  width: 620px;
  height: 100vh;
  padding: 62px 0px;
}

img.thumb {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.thumb {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.thnku h3 {
  text-align: center;
}

.error {
  color: red;
  position: relative;
  bottom: 12px;
  font-size: 14px;
  font-weight: 400;
}

button {
  width: 118px;
}

/* input,
textarea {
  margin-bottom: 31px !important;
}

select {
  margin-bottom: 31px !important;
} */
label {
  display: block !important;
  margin-bottom: 0.5rem !important;
  margin-top: 8px;
}

.form h6 {
  margin-bottom: 32px;
  margin-top: 6px;
  font-weight: 400;
}

.form h3 {
  font-weight: 400;
  margin-bottom: 25px;
  font-size: 25px;
}

button {
  transition: 0.9s;
}

button:hover {
  background: transparent;
  color: rgb(150 106 77) !important;
}

.leftarrow {
  position: relative;
  top: 1px;
  right: 8px;
}

.rightarrow {
  position: relative;
  top: 1px;
  left: 8px;
}

button:hover .rightarrow,
button:hover .leftarrow {
  filter: brightness(0.6);
}

.fullinputNumber input {
  width: 100% !important;
}

/* .fullinput input {
  width: 100%;
} */

.fullinput .addressdd {
  width: 50%;
}

select#basic_investorRelation {
  margin-top: -10px;
}

/* .fullinput2 {
  width: 100%;
} */
/* .fullinput input .addresss{
  width: 50% !important;
} */
.second h6 {
  margin-bottom: 13px;
}

.button_group .btn-secondary {
  border-radius: 0px;
  border: none;
  width: 62px;
  background: #999a99;
}

.button_group .btn-primary {
  border-radius: 0px;
  width: 62px;
  background: #545b62;
  border: none;
}

.form .btn-primary:hover {
  color: #fff;
  background-color: #927562;
  border-color: #b69783;
}

button.toogles.active {
  border: 1px solid var(--border-color) !important;
  background: #b69783 !important;
}

.button_group {
  display: table;
  /* margin-left: auto; */
}

.fullinput.same {
  margin-top: 27px;
  margin-bottom: 11px;
}

.fullinput.same label {
  color: #282829;
  font-weight: 600;
}

/* .preview {
  border: 2px solid var(--border-color);
}
.preview:hover {
  background: #9b6f52;
  border: #9b6f52;
} */
.button_group.toogleset {
  position: relative;
  bottom: 43px;
}

.form.print h4 {
  margin: 23px 0px;
}

label.btn-1 {
  position: absolute;
  bottom: 16px;
  cursor: pointer;
}

.ui.selection.dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: none;
  border-radius: 0px;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  margin-bottom: 18px;
}

.selectbox .ui.search.dropdown > input.search {
  background: none #f9f9f9 !important;
  cursor: pointer;
}

.selectbox .ui.selection.dropdown {
  border: none;
}

.selectbox .col-6 {
  padding: 0;
}

.fullinput select {
  width: 100%;
  height: 42px !important;
}

.none {
  display: none;
}

.form .react-tel-input .form-control {
  /* position: relative; */
  font-size: 14px;
  letter-spacing: 0.01rem;
  /* margin-top: 7px !important; */
  margin-bottom: 0 !important;
  padding-left: 45px;
  margin-left: 0;
  background: #f9f9f9;
  border: none;
  border-radius: 0px;
  line-height: 21px;
  height: 40px;
  width: 93%;
  outline: none;
  padding-right: 0;
}

/* .fullinput.row .col-3 {
	padding-right: 0;
}

.fullinput.row .col-6 {
	padding-left: 0;
} */
.form .ui.search.dropdown > input.search {
  background: none #f9f9f9 !important;
}

.ui.selection.dropdown {
  border: none !important;
}

.takepic {
  margin: 0 auto;
  display: table;
}

.takepics {
  display: flex;
}

.take-a-photo {
  margin-left: 32px;
}

.takepic {
  border: none;
  width: auto;
  color: #a7a09a;
  font-size: 15px;
  font-weight: 600;
}

.usual li {
  padding-bottom: 18px;
  list-style: none;
}

.mouse {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding-bottom: 61px;
  resize: none;
}
.usual.space li {
  padding-bottom: 0px;
}

.containers {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* text-transform: lowercase; */
}
.containers11 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #939394;
}

.setSelfieImage {
  margin: 0 AUTO;
  display: BLOCK;
}

/* .containers:hover input ~ .checkmark {
  background-color: #939394;
} */

.containers input:checked ~ .checkmark {
  background-color: rgb(150 106 77/1);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.containers input:checked ~ .checkmark:after {
  display: block;
}

.containers .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--border-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-my label {
  font-size: 16px;
  color: #212529;
  font-weight: 400;
  margin-bottom: 8px !important;
}

/* .checkbox-my {
  margin-left: 12px;
} */
.checkbox-my.left {
  margin-left: 0px;
}

.full {
  width: 100%;
}

.Accountant {
  width: 152px;
  font-size: 13px;
  border: none;
  background: #999a99;
  color: #fff;
  margin-top: 22px;
}

.Accountant.active-lawyer {
  background: #b69783;
  border: 1px solid var(--border-color);
}
.copybank {
  position: relative;
  top: 33px;
}

.download {
  width: 28px;
  margin-bottom: 31px;
}

.usual.back-color {
  background: #f9f9f9;
  /* padding: 28px; */
  margin-bottom: 24px;
}

button.ok {
  display: block;
  margin: 0 auto 24px;
}

button.next.setwidht {
  width: 192px;
}

.fullspace.pandingfinsh {
  width: 100%;
}

.fullspace {
  width: 100%;
}

.form .ui.search.dropdown > input.search {
  background: none #f9f9f9 !important;
  padding: 0;
  height: 42px;
  top: 0;
  margin: 3px 0px 0px;
}

.ui.search.dropdown > .text {
  top: 5px;
}

.ui.fluid.dropdown > .dropdown.icon {
  top: 14px !important;
}

.button_group.pos {
  position: absolute;
  right: 0;
  z-index: 1;
}

.id {
  margin: 0 auto 32px;
  display: table;
}

.trans {
  background: transparent;
  font-weight: 600;
  color: dimgrey;
  padding: 0;
}

.save-exit p {
  color: #b4c9d0;
  text-align: center;
  cursor: pointer;
  height: 0;
}

button.copyall {
  border: none;
  width: auto;
  position: absolute;
  top: 20px;
  right: 0;
}

button.copyall:hover {
  background: none;
  color: #fff;
}

.valuePerUnit {
  display: contents;
}

#male {
  width: 18px;
  margin-top: 5px;
}

.containerss {
  margin: 0;
  padding: 1px;
  padding-left: 7px;
}

.setradio {
  display: flex;
}

.spinner {
  margin: 0 auto;
  display: block;
  text-align: center;
}

span.againFile {
  margin-left: 16px;
  font-size: 13px;
  color: #a7a09a;
  margin-top: 21px;
}

button.signupm {
  position: absolute;
  top: 0;
  right: 6px;
  top: 6px;
  padding: 5px;
}

button.btn.btn-toogles.active.btn.btn-primary.brown {
  background: #b69783;
  border: 1px solid var(--border-color);
}

.colors {
  display: none;
}

.red {
  display: block;
}

input#maless {
  width: 31px;
  margin-top: 5px;
}

input#males {
  width: 54px;
  margin-top: 5px;
}

.setleft {
  position: relative;
  left: 33px;
  margin-bottom: 17px;
}

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
  margin: 0 auto;
  justify-content: end;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #999a99;
  color: rgb(255 255 255);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: none;
  box-shadow: none;
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  margin: 2px;
  border-radius: 0;
  font-weight: 300;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field.nospace label.toogles {
  margin: 0;
}

.switch-field input:checked + label {
  background-color: #b69783;
  box-shadow: none;
  border: 1px solid var(--border-color);
}

.button_group.space-bottom .switch-field label {
  margin: 0px;
}

.switch-field label:first-of-type {
  border-radius: 0px 0 0 0x;
}

.switch-field label:last-of-type {
  border-radius: 0 0px 0px 0;
}

label.toogles.actives {
  background-color: #b69783;
  box-shadow: none;
  border: 1px solid var(--border-color);
}

/* This is just for CodePen. */

div#hidemy {
  display: none;
}

div#showmy {
  display: none;
}

.flag-select__option {
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;
  white-space: nowrap;
}

button#select_flag_button {
  width: 93%;
  padding: 0;
  font-size: 15px !important;
}

.flag-select {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 0 !important;
  text-align: left;
  width: 100%;
  background: #f9f9f9;
}

button#select_flag_button:hover {
  background: transparent;
  color: rgb(150 106 77/1);
}

.flag-select__option__icon {
  width: 1.3em;
  height: 1.3em;
  position: relative;
  top: -2px !important;
}

button:focus {
  outline: 1px dotted;
  outline: none !important;
}

.flag-select {
  margin-bottom: 15px;
  margin-top: 3px;
  padding: 4px 0px;
}

/* The container */
.checkmystyle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.checkmystyle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 0%;
  border: 1px solid var(--border-color);
}

/* On mouse-over, add a grey background color */
.checkmystyle:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.checkmystyle input:checked ~ .checkmark {
  background-color: #b69783;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkmystyle input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkmystyle .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--border-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

span.styletest {
  margin-left: 34px;
}

.flag-select__btn:after {
  position: absolute;
  right: 8px;
  top: 16px;
}

.flag-select__options {
  width: 100%;
}

#printDate {
  margin-left: 4px;
}

canvas {
  background: #f9f9f9;
  border: 1px solid var(--border-color);
}

button.btn.btn-default.button.clear {
  background: #727272;
  color: #fff;
  margin: 22px 0px 0px;
}

.Other.box {
  display: none;
}

.None.box {
  display: none;
}

.btn_upload {
  cursor: pointer;
  /* position: relative; */
}

/* .btn_upload:hover,
.btn_upload:focus {
  background-color: #7ca9e6;
} */

.yes {
  display: flex;
  align-items: flex-start;
  margin-top: 10px !important;
}

.btn_upload input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
}

.it {
  /* height: 100px; */
  margin-left: 0px;
  font-size: 13px;
  color: var(--text-color);
}

input#removeImage2 {
  background: #6e6e6e;
  padding: 0px 0px 0px 6px;
  margin-left: 4px;
  margin-top: 4px;
}

input#removeImage7 {
  background: #6e6e6e;
  padding: 0px 0px 0px 6px;
  margin-left: 4px;
  margin-top: -2px;
}

.btn-rmv1,
.btn-rmv2,
.btn-rmv3,
.btn-rmv4,
.btn-rmv5 {
  display: none;
}

.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 0px;
  border: 1px solid var(--border-color);
  display: inline-block;
  padding: 2px 10px 2px 6px !important;
  background: var(--title-color) !important;
  margin: 0px;
  position: absolute;
  width: 23px;
}

/* .rmv:hover {
  background: #707070;
} */

#ImgPreview2 {
  margin-top: 22px;
  width: 20%;
  height: auto;
}

#ImgPreview2 {
  margin-top: 22px;
  width: 20%;
  height: auto;
  margin-bottom: 42px;
  margin-left: 0;
}

.rmv {
  width: 23px;
  padding: 2px 10px 2px 6px;
  background: #b69783;
}

.spceimg {
  margin-top: 10px;
}

.ml-50 {
  margin-left: 50px;
}

ul.flag-select__options {
  padding: 5px;
  line-height: 28px;
}

.signupForm {
  padding: 10px;
  background: #ffffff;
  border: 2px solid var(--border-color);
  transform: translateX(0);
  transition: all 0.3s ease;
  margin: 20px 0px;
}

.block #hidemy {
  display: block;
}

.fullinput.row .col-11 label {
  margin-top: 14px;
}

.fullspace.pandingfinsh .col-11 label {
  margin-top: 14px;
}

a {
  color: rgb(150 106 77/1) 000 !important;
}

.yesnoNameerror {
  position: relative;
  width: 100%;
  bottom: 0;
}

input.numbers.setinput {
  /* margin-top: 7px; */
  padding: 10px 0;
}

.display {
  display: flex;
}

a.hyperLink1 {
  margin-top: -2px;
  margin-left: 10px;
  color: #7a7979 !important;
  font-weight: 600;
}

.ui.compact.icon.button,
.ui.compact.icon.buttons .button {
  padding: 6px 2px;
  width: 24px;
}

.clndr-cell-disabled {
  cursor: default;
  background: #9b6f52 !important;
  color: #fff !important;
  opacity: 1 !important;
  text-align: center;
}

[role='button'] {
  text-align: center;
}

.ui.icon.input > i.icon:after,
.ui.icon.input > i.icon:before {
  top: 28% !important;
}

.ui.icon.input > input {
  background: #f9f9f9 !important;
  border: none !important;
  border-radius: 0 !important;
}

i.icon.angle.double.right:before {
  margin-right: 6px;
}

i.icon.angle.double.left:before {
  margin-right: 22px;
}

.clndr-calendars-segment.clndr-floating {
  top: 35px;
}

.form select {
  font-weight: 200;
}

.button_group.space-bottom .step-change.active {
  background-color: #b69783 !important;
  box-shadow: none;
  border: 1px solid var(--border-color) important;
}

.step-change {
  background: #999a99 !important;
  border: none !important;
}

.step-change.actives {
  background-color: #b69783 !important;
  box-shadow: none !important;
  border: 1px solid var(--border-color) important;
}

.ui.icon.input > input {
  width: 537px;
}

/* video{
  display: none
} */
.colors .col-11 label {
  margin-top: 14px;
}

span.error.file {
  bottom: 0;
  margin-left: 14px;
}

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: #f7f3f0;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 3;
  border: 4px solid var(--border-color);
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #9b6f52;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

span.loader {
  display: block !important;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Preloader */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* change if the mask should have another color then white */
  z-index: 99;
  /* makes sure it stays on top */
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  /* centers the loading animation horizontally one the screen */
  top: 50%;
  /* centers the loading animation vertically one the screen */
  background-image: url(https://raw.githubusercontent.com/niklausgerber/PreLoadMe/master/img/status.gif);
  /* path to your loading animation */
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
  /* is width and height divided by two */
}

.numberSet.col-3 {
  flex: 0 0 25%;
  max-width: 20%;
}

.numberSets.col-6 {
  flex: 0 0 60%;
  max-width: 55%;
}

.ui.icon.input > i.icon {
  top: 18px !important;
  height: auto !important;
}

.swal-icon--success__line--tip {
  width: 25px;
  left: 4px;
  top: 47px;
}

.swal-button {
  border: 1px solid var(--title-bg_color) !important;
  color: #fff !important;
  background-color: var(--title-bg_color) !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  background-color: transparent;
}

.swal-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal-button:not([disabled]):hover {
  background-color: #fff !important;
  color: #202020 !important;
}

.swal-text {
  color: #202020;
  /* font-weight: 100 !important; */
  font-size: 16px;
  font-family: revert;
}

#terms {
  height: 200px;
  overflow-y: scroll;
}

#terms {
  height: 200px;
  overflow-y: scroll;
  padding: 0px 13px;
}

#termss {
  height: 200px;
  overflow-y: scroll;
  padding: 0px 13px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: rgb(150 106 77/1) !important;
  border-radius: 30px;
}

input.toogles.postalAddressButton.btn.btn-secondary.active {
  color: #fff !important;
  background-color: rgb(150 106 77/1) !important;
  border-radius: 30px;
}

.mmyset {
  position: relative;
}
.sizeText {
  margin-top: 25px;
  font-size: 18px;
}
.info.myset {
  position: absolute;
  top: 5px;
  left: 52%;
  cursor: pointer;
}

.deisablecrouse {
  cursor: not-allowed;
}

.error.postalError {
  bottom: 4px;
}

.ui.disabled.input,
.ui.input input[disabled] {
  opacity: 1.45;
}

span.error.countryOfResidence {
  bottom: 6px;
}

div#result {
  color: red;
  position: relative;
  bottom: 12px;
}

span.error.takepics {
  text-align: center;
  left: 38%;
  bottom: 4px;
}

/* .info {
  float: right;
  position: absolute;
  bottom: 49px;
  font-size: 11px;
  right: 5px;
  top: 14px;
  cursor: pointer;
} */
.info {
  width: 19px;
  float: right;
  position: absolute;
  bottom: 50px;
  font-size: 11px;
  right: -30px;
  top: 15px;
  cursor: pointer;
  /* filter: invert(1); */
  transition: 0.9s;
}

.jjjj {
  /* filter: invert(1); */
  transition: 0.9s;
}

.info:hover,
.jjjj:hover {
  transform: scale(1.2);
}

img.info.informbutton {
  /* filter: invert(1); */
  margin-right: 17px;
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(10)
  > div:nth-child(2)
  > div
  > div.flag-dropdown.open
  > ul
  > li:nth-child(88)
  span.country-name {
  position: relative !important;
  top: -32px !important;
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(10)
  > div:nth-child(2)
  > div
  > div.flag-dropdown.open
  > ul
  > li:nth-child(88)
  span.dial-code {
  position: relative !important;
  top: -32px !important;
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(10)
  > div:nth-child(2)
  > div
  > div.flag-dropdown.open
  > ul
  > li:nth-child(88)
  li.country {
  height: 30px !important;
}

span.country-name {
  font-size: 12px;
}

span.dial-code {
  font-size: 12px;
}

.error-list {
  color: rgb(111 111 111);
  font-weight: 100 !important;
  font-size: 14px;
  font-family: revert;
  padding: 0;
}

.error-list a {
  color: rgb(111 111 111) !important;
  font-weight: 100 !important;
  font-size: 14px;
  font-family: revert;
}

.button-lg.main-page nav.navbar.navbar-expand-md.navbar-light {
  background: initial !important;
  position: initial !important;
}

.button-lg.main-page ul.mr-auto.navbar-nav {
  display: none;
}

.button-lg.main-page nav.navbar.navbar-expand-md.navbar-light.bg-light {
  padding: 0;
  width: auto;
}

.button-lg.main-page .btn-primary {
  color: #0b0909;
  background-color: initial;
  border-color: initial;
  border: 0;
  padding: 0;
  text-align: left;
}

.checkbox-my.left-more {
  margin-left: 42px !important;
}

span.error.setSelfieImage {
  text-align: center;
  bottom: -8px;
}

.css-yk16xz-control {
  background: #f9f9f9 !important;
  height: 40px !important;
  padding: 0 !important;
  margin-bottom: 42px !important;
  border: none;
}

.css-1wa3eu0-placeholder {
  top: 18px !important;
}

.css-tlfecz-indicatorContainer {
  display: none !important;
}

span.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-yk16xz-control {
  border: none !important;
}

.css-1uccc91-singleValue {
  top: 30% !important;
}

.css-1uccc91-singleValue {
  top: 19px;
}

.css-1pahdxg-control {
  height: 0 !important;
  margin-bottom: 42px;
}

svg.css-6q0nyr-Svg {
  display: none;
}

button#select_flag_button {
  overflow: hidden;
}

/* path {
  display: none !important;
} */
.m-signature-pad--body canvas {
  width: 100%;
}

span.error.takePhoteError {
  bottom: -7px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: block;
}

span.error.uploadPhoteError {
  bottom: 0;
}

.grid-item {
  width: 51px;
}

li.country .flag.id {
  margin: 0px auto;
  margin-right: 7px;
}

.fullspace.pandingfinsh.block.setpadding {
  padding: 15px;
}

img.width-set {
  width: 540px;
  max-width: 100%;
}

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 222px;
}

input#radio-one2 {
  position: absolute;
}

.switch-fieldd.nospacee {
  display: flex;
  align-items: center;
}

.switch-fieldd.nospacee label {
  color: #fff;
  padding: 5px;
  font-size: 14px;
  border-radius: 0;
  font-weight: 300;
}

.button_group.space-bottom .step-changes.active {
  background-color: #b69783 !important;
  box-shadow: none;
  border: 1px solid var(--border-color) important;
}

input#radio-three {
  z-index: 999949;
  position: absolute;
  opacity: 0;
  left: -1px;
  width: 187px;
  margin-left: 0;
  height: 52px;
}

input#radio-four {
  z-index: 999949;
  position: absolute;
  opacity: 0;
  left: -1px;
  width: 187px;
  margin-left: 0px;
  height: 52px;
}

.switch-fieldd.nospacee .step-change {
  width: 102px;
  cursor: pointer;
}

.switch-fieldd .step-change {
  color: #fff;
  padding: 11px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 162px;
  cursor: pointer;
}

.spcewidths .switch-fieldd .step-change {
  width: 262px;
  cursor: pointer;
}

.red-dom .joint-acc {
  z-index: 999999;
  position: absolute;
  opacity: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  cursor: pointer;
}

.red-dom {
  position: relative;
}

.spcewidths label.step-change {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  padding: 9px;
}

.red-dom label.step-change {
  margin-bottom: 0 !important;
}

.spcewidths .step-change.active {
  background: #9b6f52 !important;
  border: 1px solid var(--border-color) mportant;
}

.switch-fieldd.nospacee.wids .step-change {
  width: 71px;
  cursor: pointer;
}

.switch-fieldd.nospacee.wids .step-change.active {
  background: #b69783 !important;
  border: 1px solid var(--border-color) mportant;
}

/* input[type="text"] {
  color: #a2a2a2 !important;
} */
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #f9f9f9 !important;
  background-image: none !important;
  color: -internal-light-dark(#a2a2a2, white) !important;
  color: #a2a2a2 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* -webkit-text-fill-color: #a2a2a2 !important; */
  box-shadow: 0 0 0px 1000px #f9f9f9 inset;
}

.ui.icon.input > input:-webkit-autofill,
.ui.icon.input > input:-webkit-autofill:hover,
.ui.icon.input > input:-webkit-autofill:focus,
.ui.icon.input > input:-webkit-autofill:active {
  /* -webkit-text-fill-color: #a2a2a2 !important; */
  box-shadow: 0 0 0px 1000px #f9f9f9 inset;
}

input {
  color: var(--text-color) !important;
}

.button_group.irdspace input {
  color: rgb(150 106 77/1) !important;
}

.mb-custom {
  margin-bottom: 0 !important;
}

.dr-zero {
  float: right;
}

.button_group.irdspace input.toogles {
  width: auto;
  padding: 8px 16px;
  line-height: 1.2;
  background: transparent;
  border-radius: 30px !important;
}

.rmv {
  color: #fff !important;
}

.position-na {
  bottom: 0;
}

.error.countryOfBirth {
  bottom: 10px;
}

.error.postals {
  bottom: 0;
}

.infos {
  top: -5px;
  position: relative;
}

ul.d-none.d-md-block.navbar-nav {
  position: absolute;
  top: 14px;
  z-index: 1;
}

/* .checkbox-my span.error {
  margin-left: 36px;
} */

input.mouse.otherInput {
  margin-bottom: 17px !important;
  margin-top: -8px;
}

span.error.investmentProductExpOther {
  margin-left: 0;
  bottom: 8px;
}

.pl-34 {
  padding-left: 34px;
}

.fotter-btn.btn-right {
  margin-left: auto !important;
  /* display: table !important; */
}

ul.welcome-space li {
  margin-bottom: 0.5rem;
}

span.error.postalad {
  bottom: -3px;
}

.error.errorroll {
  position: absolute;
  bottom: -27px;
}

.width-set .red-dom label {
  width: 55px !important;
}

.spceimg input {
  width: 23px;
}

.space-btn {
  display: flex;
}

.space-btn label {
  width: 154px !important;
  margin: 5px !important;
  padding: 10px;
}

.ful_width input {
  width: 100% !important;
}

.btn-set1 {
  width: auto;
  position: relative;
  top: -12px;
  height: auto;
  background: #b69783;
}

.btn-set1:after {
  width: 100%;
  position: absolute;
  content: '';
  background: url(../src/pages/Delete.png);
  height: 100%;
  display: block;
  top: 0;
  left: 0;
}

.pikercss {
  margin-top: 7px;
  height: 73px;
}

.trustee_pikercss {
  margin-top: -17px;
  height: 48px;
}

.trustee_pikercss label {
  padding: 12px;
}

button.btncolor,
label.toogles.actives.comp_dir_btn {
  background: #999a99;
  color: #fff;
  border: none;
  width: 166px;
  padding: 0.5rem 5px;
  border: 1px solid var(--border-color);
}

label.toogles.actives.comp_dir_btn {
  padding: 0.7rem 5px;
}

button.btncolor:hover,
label.toogles.actives.comp_dir_btn:hover {
  background: #9b6f52;
  color: #fff;
  border: 1px solid var(--border-color);
}
/* .check_YN.check_b input.toogles.active.btn.btn-primary{
  background-color: rgb(150 106 77/1)000 !important;
  color: #ffffff !important;
} */

.custom-margin {
  margin-left: 48px;
  margin-top: 0;
}

.b-0 {
  bottom: 0;
}

.b-5 {
  bottom: 5px;
}

.error_text {
  bottom: 0;
  text-align: center;
  display: block;
}

.swal-content__div {
  color: rgb(111 111 111);
  font-weight: 100 !important;
  font-size: 14px;
}

.minusten {
  margin-top: -7px;
}

.width-100 label {
  width: 100%;
}

.switch-field.margin-btn label {
  margin: 0;
}

.new-position {
  position: absolute;
  bottom: 5px;
}

.pos-under {
  position: absolute;
  left: 15px;
  top: 56px;
}

.onlyshare.row:nth-child(odd) {
  display: none;
}

.deleteshow {
  display: none;
}

.fullinput.onlyshare.row.main {
  display: flex;
}

.onlydirector.row:nth-child(odd) {
  display: none;
}

.deletedirector {
  display: none;
}

.fullinput.onlydirector.row.main {
  display: flex;
}

label.heading-margin {
  margin-bottom: 32px !important;
  margin-top: 6px;
}

:is(button.coffee-colortwo, label.toogles.actives.comp_dir_del_btn) img {
  max-width: 20px;
}

button.coffee-colortwo,
label.toogles.actives.comp_dir_del_btn {
  background: #b69783;
  width: 47px;
  height: 42px;
  padding: 0px;
  border: 2px solid var(--border-color);
}

label.toogles.actives.comp_dir_del_btn {
  padding: 8px;
}

button.next.setwidht.width_oneeighteen {
  width: 118px;
}

.policy_font {
  font-size: 16px;
}

.t-55 {
  top: 55px;
}

.d-none.hideFieldd {
  display: flex !important;
}

@media screen and (min-width: 786px) {
  .m-signature-pad--body canvas {
    width: 100%;
  }

  video {
    width: 100% !important;
  }
}

.hideField {
  display: none;
}

@media screen and (min-width: 1400px) {
  .usual.back-color.terms_cond {
    height: 199.9px !important;
  }
}

img.MuiBox-root.css-1ngw63v {
  display: none;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-47ifef-MuiToolbar-root {
  border-bottom: 1px solid var(--border-color);
}

.main-loader {
  display: flex;
  justify-content: center;
}

.loader {
  border: 8px solid var(--title-bg_color);
  border-radius: 50%;
  border-top: 8px solid var(--border-color);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

a.anchorfont {
  font-size: 16px;
  color: #007bff !important;
  text-decoration: underline;
  display: inline-block;
  margin-left: 4px;
}

.toggle-switch-inner:before {
  width: 37px;
  background-color: rgb(150 106 77/1) 000;
  height: 37px;
  border-radius: 50%;
}

.toggle-switch-label {
  position: relative;
}

.toggle-switch-switch {
  display: none;
}

select.colorgrey {
  color: var(--input-sel-color) !important;
}

.progress {
  width: 100%;
  max-width: 240px;
  height: 6px;
  background: #e1e4e8;
  border-radius: 3px;
  overflow: hidden;
  margin: 12px 0;
}

.progress .progress-bar {
  display: block;
  height: 100%;
  background: linear-gradient(
    90deg,
    #555248,
    #757073 17%,
    #100d13 34%,
    #171e1e 51%,
    #d6d6cd 68%,
    #ffffff 69%,
    #665e5e
  );
  background-size: 300% 100%;
  animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.red {
  color: red;
}

.iconstyle {
  margin-left: -12px;
  margin-right: 8px;
}

.downloads {
  width: 30px;
  height: 30px;
  background: var(--title-bg_color);
}

.downloadeStyle {
  color: #fff;
  text-align: center;
  margin-left: 9px;
  margin-top: 7px;
  font-size: 13px;
}

.licedocument-downloade {
  display: flex;
  width: 100%;
}

.main-downloade-wrapper {
  display: flex;
  width: 100%;
}
p.opps {
  margin-bottom: 8px;
  text-align: center;
}

.downloadeMsg {
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  /*  text-transform: uppercase; */
  margin-top: 4px;
  margin-left: 15px;
}

/* new changes 11-12 css */
.alignment {
  margin-top: 6px;
  margin-left: 26px;
}

.leftalignment {
  margin-left: 25px;
}

.listalignment {
  padding-left: 5px;
}

li.orderListAlignment {
  padding-left: 10px;
  margin-bottom: 5px;
}

.spacetop {
  margin-top: 5px;
}

p {
  font-family: 'Roboto', sans-serif !important;
}

form {
  font-family: 'Roboto', sans-serif !important;
}

li {
  font-family: 'Roboto', sans-serif !important;
}

/* new changes */
.btn-box.border_BTN {
  margin-bottom: 10px;
}

.circle-box .avatarname {
  width: 85px;
  background: #d8d8d8;
  height: 85px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin: auto;
}

.circle-box .avatarname span {
  font-size: 40px !important;
  font-weight: 500 !important;
  text-align: center;
}

.hh {
  margin-left: 30px !important;
  /* margin-bottom: 50px; */
}

.tt {
  margin-left: 25px;
}

/* new css */
.left_Menus {
  width: 280px;
  padding: 30px 15px;
  background: #f1eeeb;
  height: calc(100vh - 110px);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_Menus .logout__bTN button {
  background-color: rgb(150 106 77/1);
  color: #fff !important;
  border: 1px solid var(--title-bg_color);
  justify-content: center;
  padding: 0;
  min-height: 47px;
  font-weight: 500;
}


.swal2-styled.swal2-cancel{
  background-color:var(--title-color) !important;
  border: 1px solid var(--title-color) !important;
  /* background-color: rgb(150 106 77) !important; */
}

.left_Menus .logout__bTN button:hover {
  background-color: #fff;
  color: rgb(150 106 77/1) !important;
}

.left_Menus button {
  background: #fff;
  color: #282829 !important;
  font-size: 14px !important;
  padding: 10px 11px;
  width: 100% !important;
  justify-content: flex-start;
  display: flex;
  margin: 6px 0;
  /* background-color: #d8d8d8; */
  border-radius: 8px;
  position: relative;
  font-weight: 500 !important;
  border: none;
  text-transform: capitalize !important;
  justify-content: flex-start;
}

.left_Menus button i {
  font-weight: 500;
  padding-right: 8px;
  font-size: 18px;
}

.left_Menus button:hover {
  background: rgb(150 106 77/1);
  color: #fff !important;
}

.left_Menus button.active {
  background: rgb(150 106 77/1);
  color: #fff !important;
}

.content__box__main.css-1byr0tz {
  display: flex;
  padding-top: 111px;
  padding-left: 0;
  padding-right: 0;
  /* position: fixed; */
  position: relative;
  width: 100%;
  padding-bottom: 0;
}

.main_container {
  width: calc(100% - 280px);
  position: relative;
  max-width: 937px;
  background-color: var(--white);
  margin-left: 280px;
  margin-top: 0;
}
.css-1byr0tz {
  background: #fff;
}
.nav_logo.circle-box + p {
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  font-size: 18px;
}

.left_Menus button svg {
  height: 28px;
  font-size: 28px;
}

.header_TP button:hover {
  color: #fff !important;
}

.main-loader {
  background: #fff;
  float: left;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
}

.main-loader .loader {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.maisdn > button {
  display: none;
}

@media screen and (max-width: 1199px) {
  .main_container {
    /* width: calc(100% - 320px); */
    /* position: absolute;
    right: 20px; */
    /* background-color: #D5D5D5;
    padding-right: 20px; */
    padding-right: 20px;
  }
}

@media screen and (max-width: 991px) {
  .main_container {
    width: calc(100% - 30px);
    position: relative;
    right: 0;
    left: 0;
    margin: auto;
    padding-right: 0;
  }

  .form {
    max-width: 100%;
    margin-left: 0;
  }

  /* .left_Menus {
    display: none;
  } */
  .maisdn > button {
    display: block;
  }

  .maisdn > button {
    position: fixed;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 124px;
    left: 21px;
    height: 36px;
    width: 36px !important;
    padding: 0 !important;
    font-size: 22px !important;
  }

  .left_Menus {
    left: -280px;
    transition: 0.4s;
    opacity: 0;
  }

  .is_active .left_Menus {
    left: 0;
    opacity: 1;
  }

  .is_active .left_Menus:after {
    position: absolute;
    content: '';
    left: 100%;
    width: 1200px;
    height: 100%;
    z-index: 9999;
    top: 0;
    background: rgba(4, 4, 4, 0.3);
  }

  .maisdn.is_active .fa-bars {
    display: none;
  }

  .maisdn .fa-times {
    display: none;
  }

  .maisdn.is_active .fa-times {
    display: block;
  }
}

pre,
span {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
}

.subscription {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
}

.left-content {
  flex: 1;
  font-weight: 600;
}

.center-content {
  flex: 2;
  font-weight: 600;
}

.right-content {
  flex: 1;
  font-weight: 600;
}

.table-box,
.second-main-wrapper {
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 25px;
}

.table-term-condition,
td {
  font-weight: 600;
  padding: 5px;
}

.widthTD {
  width: 10%;
  padding: 5px !important;
}

.widthTD-2 {
  width: calc(100% - 70%);
  padding: 5px !important;
}

.widthTD-3 {
  width: 20%;
  padding: 5px !important;
}

.ExecutedAgg {
  letter-spacing: 1px;
}

table.second-part-content-table {
  width: 100%;
  text-align: center;
}

table.second-part-content-table td {
  text-align: center;
}

table.second-part-content-table select.colorblack {
  width: 132px;
}

td.term-entity {
  width: 40%;
  text-align: center;
}

table.behalf-sign {
  margin-bottom: 20px;
}

.main-second-text-content {
  margin-bottom: 10px;
}

.ORDER-Content {
  padding-left: 10px;
  padding-inline-start: 22px;
}

.ORDER-Content li {
  padding-left: 10px;
  margin-bottom: 10px;
}

.list-header {
  font-weight: 600;
  padding: 10px 5px;
}

.main-second-text-content ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.main-second-text-content li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.main-second-text-content li:before {
  content: counters(item, '.');
  display: table-cell;
  padding-right: 0.6em;
}

.main-second-text-content li li {
  margin: 0;
  margin-bottom: 10px;
}

.main-second-text-content li li:before {
  content: counters(item, '.') ' ';
}

.main-second-text-content li.parent:before {
  content: '';
}

.list-Alphabet {
  list-style-type: lower-alpha;
}

.main-wrapper-term {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.maisdn {
  position: absolute;
  z-index: 9;
}

/* new close  css */
pre,
span {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
}

.subscription {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
}

.left-content {
  flex: 1;
  font-weight: 600;
}

.center-content {
  flex: 2;
  font-weight: 600;
}

.right-content {
  flex: 1;
  font-weight: 600;
}

.table-box,
.second-main-wrapper {
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 25px;
}

.table-term-condition,
td {
  font-weight: 600;
  padding: 5px;
}

.widthTD {
  width: 10%;
  padding: 5px !important;
}

.widthTD-2 {
  width: calc(100% - 70%);
  padding: 5px !important;
}

.widthTD-3 {
  width: 20%;
  padding: 5px !important;
}

.ExecutedAgg {
  letter-spacing: 1px;
}

table.second-part-content-table {
  width: 100%;
  text-align: center;
}

table.second-part-content-table td {
  text-align: center;
}

table.second-part-content-table select.colorblack {
  width: 132px;
}

td.term-entity {
  width: 40%;
  text-align: center;
}

table.behalf-sign {
  margin-bottom: 20px;
}

.main-second-text-content {
  margin-bottom: 10px;
}

.ORDER-Content {
  padding-left: 10px;
  padding-inline-start: 22px;
}

.ORDER-Content li {
  padding-left: 10px;
  margin-bottom: 10px;
}

.list-header {
  font-weight: 600;
  padding: 10px 5px;
}

.main-second-text-content ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.main-second-text-content li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.main-second-text-content li:before {
  content: counters(item, '.');
  display: table-cell;
  padding-right: 0.6em;
}

.main-second-text-content li li {
  margin: 0;
  margin-bottom: 10px;
}

.main-second-text-content li li:before {
  content: counters(item, '.') ' ';
}

.main-second-text-content li.parent:before {
  content: '';
}

.list-Alphabet {
  list-style-type: lower-alpha;
}

.main-wrapper-term {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.font-bold {
  font-weight: 700;
}

.padding-left-term {
  padding-left: 5px;
}

.space-bottom-wrapper {
  margin-left: 33px;
}

.bold-bottom-space {
  margin-bottom: 5px;
  display: block;
}

.bold-Partners {
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 16px;
}
.account-tba {
  margin-bottom: 0;
  margin-left: 16px;
}

.wrapper-Partners {
  margin-left: 25px;
}

.second-part-content-table {
  text-transform: capitalize;
}

table.second-part-content-table select.colorblack {
  width: 140px;
  margin: auto;
  margin-bottom: 0 !important;
}

.logoBlack {
  width: 200px;
}

.Blackbg-logo {
  background-color: rgb(150 106 77);
  padding: 20px;
  border-radius: 9px;
  border: 2px solid var(--border-color);
}

.logo-Blackbg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.onpage {
  color: #fff;
}

.main-FundNAME-AGREEMENT {
  color: #fff;
}

.FundNAME-AGREEMENT {
  display: flex;
  justify-content: space-between;
}

.FundNAME-AGREEMENT .ADHERENCE {
  margin-bottom: 0px;
}

.logo-Blackbg .TermsLOgo {
  width: 100px;
}

.Seconf-part-AGREEMENT {
  background: #d5d5d5;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.outer-style-AGREEMENT {
  background: #fff;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 5px 7px;
  height: auto;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.main-wrapper-AGREEMENT {
  display: flex;
  align-items: center;
}

.FundNAME-AGREEMENT .input-date label {
  color: #fff;
}

.main-wrapper-AGREEMENT {
  display: flex;
  align-items: center;
}

.AGREEMENT-left {
  width: 70px;
  margin-right: 10px;
}

.AGREEMENT-center {
  width: calc(100% - 280px);
  margin-right: 10px;
}

.AGREEMENT-center-span {
  width: calc(100% - 280px);
}

.right-AGREEMENT {
  width: 179px;
}

.outter-AGREEMENT {
  margin: 10px;
}

.PARTNER-info {
  width: 100%;
}

.PARTNER-info .PARTNER-info-content {
  border: 2px solid var(--border-color);
  width: 234px;
  max-width: 500px;
  padding: 5px 10px;
}

.PARTNER-info .PARTNER-info-content-second {
  width: calc(100% - 211px);
  border: 2px solid var(--border-color);
  word-break: break-word;
}

.signature-content {
  margin-left: 0px;
  background-color: #d0d0d0;
  padding: 20px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
}

.main-signature-content {
  margin-bottom: 20px;
}

.round-EXECUTED {
  border: 2px solid var(--border-color);
  background: #d5d5d5;
  border-radius: 10px;
  width: 200px;
  margin-right: 20px;
  padding: 15px;
}

.paddingNone {
  padding: 0 !important;
  text-align: center;
  margin: 0;
  margin-left: 10px;
}

.box-EXECUTED-signature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.first-EXECUTED-signature {
  display: flex;
  width: 100%;
}

.second-EXECUTED-signature {
  display: flex;
  width: 100%;
}

.second-EXECUTED-signature p {
  width: 33.33%;
  font-size: 14px;
  padding: 10px;
  font-weight: 700;
}

.box-EXECUTED-signature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.main-signature-content {
  margin-bottom: 50px;
}

.content-AGREEMENT input {
  display: inline-block;
  margin-bottom: 5px !important;
}

.content-AGREEMENT .AGREEMENT-input {
  background: #d5d5d5;
  border: 2px solid var(--border-color);
  border-radius: 20px;
}

.swal2-styled.swal2-confirm {
  background-color: rgb(150 106 77) !important;
  border: 1px solid var(--title-color) !important;
}

.swal2-title {
  font-size: 22px !important;
}

.swal2-html-container {
  font-size: 17px !important;
}
.margin_left {
  margin-left: auto;
}
.attach-styhle-proof {
  margin-left: 25px;
  margin-top: 4px;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow:none !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-color: #fff !important;
    color: #202020 !important;
    /* border: 1px solid var(--title-bg_color); */

}

.check_YN_Bank input {
  width: auto;
}

.Subscription-fund {
  color: #fff;
}

.input-date .colorChanges {
  color: #fff;
}

.Signature-person {
  width: 100%;
}

.save-exit {
  position: absolute;
  width: 100px;
  top: 23px;
  right: 51px;
}

.se_invff p.save.next {
  min-height: 47px;
  background: var(--title-bg_color);
  border: 1px solid var(--title-color);
  border-radius: 15px;
  font-weight: 500;
}

.save-exit p {
  color: #ffffff !important;
  font-size: 14px !important;
  line-height: 47px;
}

/* .info {
  width: 13px;
  float: right;
  position: absolute;
  bottom: 50px;
  font-size: 11px;
  right: 8px;
  top: 11px;
  cursor: pointer;
} */

.form {
  max-width: 937px;
}

.heading__top h2 {
  font-size: 58px;

}

td.term-entity {
  width: 43%;
  text-align: center;
  background: #d0d0d0;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  text-transform: capitalize;
}

.content-AGREEMENT .AGREEMENT-input {
  background: #d5d5d5;
  border: 2px solid var(--border-color);
  border-radius: 20px;
  display: inline-block;
  width: 50%;
  text-align: center;
}

label.AGREEMENT-input-date {
  font-weight: 900;
  display: inline-block !important;
  margin-bottom: 0 !important;
  margin-left: 8px;
  margin-right: 8px;
}

.EXECUTED-content_top_cnt {
  margin: 14px 0px 20px 0px;
  /* border: 2px solid var(--border-color)*/
  /* padding: 15px; */
}

.EXECUTED-content_top_cnt label {
  margin-bottom: 0 !important;
  padding: 4px 8px;
}

.left_Menus i.fas.fa-check {
  padding-right: 0;
  padding-left: 5px;
  float: right;
  margin: 0 0 0 auto;
}

.error1 {
  bottom: 8px;
}

.EXECUTED-content_top_cnt .round-EXECUTED {
  border-radius: 10px;
  width: 33.3%;
}

/* 8feb css */
.clickAttach {
  background: rgb(150 106 77);
  color: #fff;
  padding: 10px 21px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px solid var(--border-color);
  border: 2px px solid var(--border-color);
  border-radius: 5px;
}

.Upload-account {
  /* margin-top: 20px; */
}

.attach-style {
  margin-top: 6px;
}

.attach-style h6 {
  text-align: justify;
  margin-bottom: 15px;
}

.click-hover {
  position: relative;
}

.dropdown-mobile {
  /* max-width: 122px; */
  padding-right: 0 !important;
}

.counrtyselext5-phone {
  padding-left: 10px !important;
}

.InputMask-number {
  max-width: 265px;
}

.attach-style-proof {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.left_Menus {
  overflow-x: hidden;
  overflow-y: auto;
}

.round-EXECUTED.paddingNone select {
  display: inline-block;
  margin-bottom: 15px !important;
}

.EXECUTED-content_top_cnt .round-EXECUTED {
  border-radius: 10px;
  position: relative;
  width: 33.3%;
  min-height: -10px;
  /* max-height: 31px; */

  /* min-height: 30px; */

  display: flex;
  align-items: center;
  justify-content: center;
}

.round-EXECUTED.paddingNone select {
  display: inline-block;
  padding: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: none;
}

.aa {
  width: 100%;
}

.darktext {
  font-weight: 500;
  margin: 5px 0;
}

.fffff {
  /* filter: invert(1); */

  padding-left: -9px;

  display: inline-block;
}

.border_trans_btn {
  color: rgb(150 106 77/1) 000 !important;
  font-weight: 700 !important;
  /* background: transparent; */
  max-height: 43px;
  border: 1px solid var(--title-color);
  border-radius: 15px !important;
  padding: 10px 20px;
  transition: 0.5s;
  margin-left: auto;
}

.border_trans_btn:hover {
  color: var(--title-color) !important;
  background: transparent !important;
}

.border_trans_btn:focus {
  box-shadow: none !important;
}

img.fffff {
  width: 17px;
  cursor: pointer;
  margin-left: 5px;
}

img.fffff:hover {
  width: 20px;
}

.textcolor {
  color: #b4b4b4;
}
.mobileerror{
  color: #b4b4b4 !important;
}

p.Wholesale_Investor_value {
  width: 100% !important;
  padding: 6px;
  padding-left: 0 !important;
}

.duvalpartners_Link {
  color: var(--title-bg_color) !important;
}

.inform_identity_Wrapper {
  position: relative;
  /* margin-left: -55px; */
}

.inform_identity {
  display: inline-block;
}
span.error.enterPhone {
  float: left;
}

.inform_identity_informbutton {
  display: inline-block;
  width: 19px;
  margin-left: 12px;
  cursor: pointer;
  /* filter: invert(1); */
  transition: 0.9s;
}

.inform_identity_informbutton:hover {
  transform: scale(1.1);
}

.certificate_main {
  max-width: 1000px;
  margin: auto;
}

.warning,
.acknowledgement,
.executed_input,
.cetof_logo {
  background: #e5e5e5;
  padding: 15px;
}

.warning p b {
  color: gray;
  font-size: 14px;
}

.finacial {
  margin: 15px 0 30px;
}

.acknowledgement + p {
  margin-top: 50px;
}

.warning h3,
.acknowledgement h3 {
  font-size: 16px;
  color: gray;
}

.for_input {
  max-width: 500px;
}

.for_input input {
  max-width: 340px;
  width: 100%;
  background: #fff;
  border: 0;
  outline: none;
  height: 40px;
  border-radius: 10px;
  margin: 0 5px 15px;
}

.manage_input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -7px;
}

.input_group {
  width: 25%;
  padding: 0 7px;
}

.custom_input input {
  width: 100%;
  height: 45px;
  border: 0 !important;
  outline: none;
  border-radius: 10px;
  padding: 10px;
}

.custom_input label {
  margin: 5px 0 15px;
  font-size: 13px;
}

.cetof_logo {
  margin-bottom: 30px;
  margin-top: 20px;
}

.logo_img img {
  max-width: 100px;
  width: 100%;
  margin-bottom: 15px;
}

.logo_text h2 {
  color: gray;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
}

.logo_text h3 {
  font-size: 20px;
  color: gray;
  margin: 0;
}

/* hfjdhfjhdjfhjdhfjd */
.save-exit.tempr.enternow {
  position: initial;
  margin: auto;
  display: table;
}

/* gdfjdgsfgjgdfj */

a.duvalpartners_Link {
  text-decoration: underline;
}

/* .certificate_main .checkmark {
  height: 20px;
  width: 20px;
  margin-top: 5px;
} */
.review_bottom_p p {
  width: 100% !important;
  padding-left: 0;
}

.review_bm {
  flex-direction: column !important;
}

.fillColor {
  color: red;
}

.left_menu11 {
  font-size: 17px;
  font-weight: 400;
}

/* new changes css */
.wholesale_content_header {
  background: #e2e2e2;
  /* max-width: 100%; */
  margin-left: -45px;
  width: 830px;
  /* padding: 20px 45px; */
  padding: 20px 45px 5px 45px;
  margin-bottom: 30px;
}

.logo_Wholesale {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.logobLACK {
  width: 200px;
}

h3.Wholesale_headering {
  font-weight: 400;
  letter-spacing: 2px;
}

p.Bold_warning {
  font-weight: 800;
  font-size: 18px;
}

.Wholesale__Investor__Conetnt {
  background-color: #e2e2e2;
  padding: 20px;
}

.Wholesale__Para {
  font-size: 18px;
}

.italic_text {
  padding: 20px;
}

.Bottom-space__Wholesale {
  margin-top: 40px;
}

input.certifies__Input {
  display: inline-block;
  border-bottom: 0;
  background: #d0d0d0;
  border-radius: 20px;
}

span.list__a {
  display: inline-block;
  width: 10%;
}

h4.jkdjk {
  font-weight: 400;
}

p.spacebtween {
  margin-left: 30px;
}

.Info_icon {
  /* filter: invert(1); */
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

p.certifies__Input.newdivadd {
  display: inline-block;
  text-align: center;
  background: #d0d0d0;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
  padding: 5px 10px !important;
  border-bottom: 1px solid var(--border-color);
  font-size: 17px;
  color: rgb(150 106 77/1) 000 !important;
  font-weight: 700;
  text-transform: capitalize;
}

p.certifies__Input.fd {
  display: inline-block;
  text-align: center;
  background: #ffffff;
  margin-left: 20px;
  margin-right: -160px;
  border-radius: 20px;
  padding: 5px 10px !important;
  border-bottom: 1px solid var(--border-color);
  font-size: 17px;
  color: rgb(150 106 77/1) 000 !important;
  font-weight: 700;
}

.se_invff p.save.next {
  transition: 0.5s;
}

.se_invff p.save.next:hover {
  background: transparent !important;
  color: rgb(150 106 77) !important;
}

.se_invff p.save.next:hover {
  color: rgb(150 106 77) !important;
}

/* .swal-wide{
  width:1000px !important;
  margin-top: 40px !important;
} */
.swal2-popup.swal2-modal.blackkk.swal2-show {
  margin-top: 112px !important;
  background: rgb(150 106 77) !important;
  color: #ffffff !important;
  border-radius: 20px !important;
}

h2#swal2-title {
  color: #ffffff !important;
  text-align: left !important;
}

div#swal2-html-container {
  /* text-align: left !important; */
}

.welcome__page {
  max-width: 700px;
  margin: 30px auto 30px;
  box-shadow: 0 0 13px 4px #d5d4d4;
}

.register_page {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  border-radius: 6px;
}

.welcome-header {
  background: rgb(150 106 77/1);
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.register_page h3 {
  text-align: center;
  font-size: 30px;
}

.wel-come_content {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  justify-content: space-between;
}

.wel-come_content a {
  color: var(--title-bg_color) !important;
}

input#basic_fullLegalName,
input#fullName {
  text-transform: capitalize;
}

.nav_logo.circle-box + p {
  text-transform: capitalize;
}

.capitallll {
  text-transform: capitalize;
}

.bioooometric .wel-come_content {
  padding: 0 !important;
}

.bio_texdt {
  padding: 20px;
}

.bio_texdt p {
  font-size: 16px;
  text-align: center;
  padding-top: 5px;
}

.sweettt {
  text-align: justify;
}

.trust_form {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.trust_form button#google_api {
  margin-right: 50px;
  padding: 10px 21px;
}

.trust_form button {
  /* background-color: transparent !important; */
  background-color: #d8d8d8 !important;
  color: rgb(150 106 77) !important;
  border-color: #d8d8d8 !important;
}

.trust_form button:hover {
  background-color: transparent !important;
  color: rgb(150 106 77) !important;
}

.trust_form .activeButton {
  background-color: rgb(150 106 77) !important;
  color: #ffffff !important;
}

.hj {
  width: 68% !important;
}

.dff {
  width: 77% !important;
  color: rgb(150 106 77/1) !important;
  border: none;
  border-bottom: 1px solidvar(--border-color);
  border-radius: 0 !important;
}

.logo_top_cont {
  background: #d5d5d5;
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.logo_top_cont a {
  text-align: left;
  display: block;
  padding: 23px;
  text-decoration: underline;
  color: var(--title-bg_color) !important;
}

.chech_box_text {
  display: flex;
  align-items: center;
}

.chech_box_text p {
  margin-left: 15px;
}

.chech_box_text input {
  width: 30px;
  height: 30px;
}

.resize {
  max-width: 190px;
}

.resize label {
  font-size: 12px !important;
}

/* .moveleft {
  margin-right: 62% !important;
} */

.dis_sign:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e1e1e133;
  cursor: pointer;
}

.dis_sign {
  position: relative;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2.FlagsSelectcss.dffk {
  margin-right: -245px;
}

.redcolor {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.sideleft {
  margin-left: 10px;
}

/* .css-10650aq {
 display:none;
 
} */
.pppp {
  background: #d5d5d5;
  height: 100%;
}

.satisfy {
  color: red;
  font-size: small;
  font-weight: 400;
  margin-top: 23px;
  /* margin-right: -5px; */
}

.swal2-container.swal2-center.swal2-backdrop-show {
  /* background: #fff; */
  /* background: grey; */
}
.swal2-container.swal2-center>.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  border: 1px solid #cccccc45;
  box-shadow: none;
  box-shadow: 0px 0px 8px 3px #cccccca6;
}

.registered {
  background: #d5d5d5;
  height: 100%;
  padding: 50px;
  /* margin-top: -30px; */
  min-height: 731px;
}

.marginB0 {
  margin-bottom: 5px;
}

a.moveleft {
  padding: 0;
  margin-bottom: 9px;
  /* text-align: left; */
}

.centername {
  /* margin-left: 32px; */
  text-align: center;
}

.textCapitalize {
  text-transform: capitalize !important;
}
p.italic_teext {
  font-style: italic;
}

/* ::marker {
  font-weight: bold;
} */

.note_text i {
  display: block;
}

p.colorred {
  margin-top: 10px;
  color: red;
}

/* swal loader */

.swal2-loader {
  border-color: rgb(150 106 77/1) 000 #dbdbdb rgb(150 106 77/1) 000 #dbdbdb !important;
}

@media screen and (max-width: 576px) {
 

  .hj {
    width: 100% !important;
  }

  .form {
    padding: 15px;
  }

  .address_data .row .col {
    order: 2;
    width: 100%;
    flex-basis: initial;
  }

  .address_data .row .col-4 {
    width: 100%;
    max-width: 100%;
    flex: initial;
    margin-bottom: 15px;
  }


  .resize {
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .dropdown-mobile {
    max-width: 100%;
    padding-right: 15px !important;
    width: 100% !important;
  }
  .fullinput.fullinputNumber.select_num_add.row .d-flex {
    flex-wrap: wrap;
  }
  .select_num_add select {
    width: 100%;
    max-width: 100% !important;
  }

  .select_num_add .numberSet {
    width: 100%;
  }
  .fullinputNumber .numberSet {
    width: 100%;
  }
  .counrtyselext5-phone {
    flex-wrap: wrap;
    width: 100% !important;
  }

  /* .fullinputNumber .numberSets {
    flex: 0 0 100%;
    max-width: 100%;
} */
}
/* trusted css */
.upper-space-table .behalf-sign td {
  border: 0;
  padding: 0;
}
.checkbox-my {
  margin-top: 15px;
}
.form.form_top_marg {
  margin-top: 66px;
}

.col_trust div input {
  margin: auto;
  opacity: 0;
  z-index: 9;
}
.col_trust div p {
  padding-left: 0 !important;
}

.col_trust .check_mark {
  width: 22px;
  height: 22px;
  border: 1px solid var(--border-color);
  position: absolute;
  left: 50%;
  top: 47px;
  transform: translateX(-50%);
}
.col_trust div {
  position: relative;
}
.col_trust div input:checked ~ .check_mark {
  background-color: rgb(150 106 77/1);
}
.col_trust .check_mark:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid var(--border-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
  left: 7px;
  display: none;
}
.col_trust div input:checked ~ .check_mark:after {
  display: block;
}
.heading__top h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 35px 162px 25px 59px;
  text-transform: capitalize;
}
/* jitendra css 28-6-22 */
/* @media screen and (max-width: 1199px) {
  .header_TP div button:first-child {
    margin-top: 23px;
  }
} */

/* jitendra css 28-6-22 */

/* trust responsive  */
@media screen and (max-width: 1141px) and (min-width: 992px) {
  p.select_all {
    width: 100%;
    text-align: center;
    margin-bottom: 15px !important;
    position: unset;
    transform: unset;
    left: 0;
    font-size: 16px !important;
  }
  .trust_row_sec {
    flex-wrap: wrap;
  }
  .trust_row_sec .col_trust {
    width: 100%;
  }
  .trust_row_sec .col_trust div p {
    margin-bottom: 10px;
  }
  .fullinput123 .space_margin {
    margin: 5px;
  }
}
/* trust responsive  */

/* trust responsive  */
@media screen and (min-width: 992px) {
  .wholesale_content_header {
    width: unset;
    margin-right: -45px;
  }
}

/* trust responsive  */
@media screen and (max-width: 999px) {

  .twentyone_two_page {
    margin-top: 65px;
  }
  /* trust responsive  */
  .trust_row_sec .col_trust p {
    padding-left: 0;
  }
  .wholesale_content_header {
    width: 100%;
    max-width: 830px;
    margin: 0 auto 30px auto;
  }

  /* trust responsive  */
}

@media screen and (max-width: 990px) {
  .heading__top h2 {
    margin: 0px 29px;
    margin-top: 69px !important;
    
  }
  .save-exit {
    top: 8px;
   
}


  /* trust responsive  */
}

@media screen and (max-width: 768px) {
  .yes .button {
    font-size: 13px;
    padding: 10px;
  }
}
@media screen and (max-width: 767px) {
  .wel-come_content,
  .form {
    padding: 20px;
  }
  .for_input {
    max-width: 100%;
  }
  .aa {
    width: 100%;
  }
  .heading__top h2 {
    margin: 0px 12px;
    font-size: 20px;
}
  /* .form.form_top_marg .save-exit {
    top: -62px;
  } */
  .numberSets .border_trans_btn {
    margin-bottom: 15px;
    color: rgb(150 106 77/1) 000 !important;
  }
  .border_trans_btn {
    color: rgb(150 106 77/1) 000 !important;
  }
  .main_container .form .border_BTN {
    margin-top: 15px;
    color: rgb(150 106 77/1) 000 !important;
  }
  .wholesale_content_header {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }
  .Seconf-part-AGREEMENT {
    margin-bottom: 15px;
  }
  .signature-content {
    margin-left: 0;
  }
  .input-date {
    margin-top: 15px;
  }
  .Blackbg-logo .logo-Blackbg {
    flex-wrap: wrap;
  }
  .form h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  /* .save-exit {
    top: -55px !important;
    right: 48px !important;
  } */
  /* .form {
    margin-top: 65px;
  } */


  /* trust responsive  */
  p.select_all {
    width: 100%;
    text-align: center;
    margin-bottom: 15px !important;
    position: unset;
    transform: unset;
    left: 0;
    font-size: 16px !important;
  }
  .trust_row_sec {
    flex-wrap: wrap;
  }
  .trust_row_sec .col_trust {
    width: 100%;
  }
  .trust_row_sec .col_trust input {
    width: 100%;
  }
  .trust_row_sec .col_trust div p {
    margin-bottom: 10px;
  }

  .fullinput .Trust_ind_btn button {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .CORPORATE__trust .trust__space_field {
    justify-content: flex-start;
    margin: unset;
  }
  .fullinput .addressdd {
    width: 100%;
  }
  .fullinput123,
  .fullinput1234 {
    flex-wrap: wrap;
  }
  .fullinput123 .space_margin {
    margin: 5px;
    width: calc(100% - 10px);
  }
  .fullinput123 .space_margin:nth-child(2) {
    width: 30%;
  }
  .fullinput123 .space_margin:nth-child(3) {
    width: 63%;
  }
  .fullinput123 .space_margin .fullinput select {
    height: auto;
  }
  /* .select_num_add .numberSet {
      width: 30%;
  } */
  .fullinput1234 .colorblack {
    max-width: unset;
  }

  .fullinput1234 .numberSets {
    width: 70%;
  }

  .fullinput1234 .numberSets .setinput1 {
    width: 100% !important;
    /* padding: 0; */
    margin-left: 0;
  }
  .fullinputNumber.select_num_add {
    margin-top: 15px;
  }
  .fullinputNumber .uniq-code {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }
  /* .fullinputNumber .uniq-code .border_trans_btn {
    width: 100% !important;
  } */
  .counrtyselext5-phone + .numberSets {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 33.333333%;
  }
  .numberSets.InputMask-number {
    flex: unset;
    max-width: unset;
  }
  .ReactFlagsSelect-module_flagsSelect__2pfa2.FlagsSelectcss.dffk {
    margin-right: 0;
  }
  .dropdown-mobile {
    width: 20%;
  }
  .counrtyselext5-phone {
    width: 80%;
  }
  .fullinputNumber .numberSets {
    width: unset;
  }
  p.certifies__Input.fd {
    margin-left: 0;
    margin-right: 0;
  }
  /* trust responsive  */
}
@media screen and (max-width: 630px) {
  .appli_rew {
    flex-wrap: wrap;
  }

  .appli_rew button {
    width: 100% !important;
    margin-top: 15px;
  }
  .bold-Partners {
    margin-left: 7px !important;
  }
  .account-tba {
    margin-bottom: 0;
    margin-left: 7px !important;
  }
  .registercss {
    flex-wrap: wrap;
  }
  .registercss .loginbuttton {
    margin-top: 15px;
    width: 100%;
  }
  .wel-come_content {
    padding: 20px !important;
  }
  .registercss .loginbuttton button {
    padding: 10px 11px;
    justify-content: center;
    width: 100% !important;
  }
  .registercss a {
    width: 100% !important;
    display: block;
    text-align: center;
  }
  .wel-come_content h3 {
    font-size: 20px;
  }
  .header_TP .button.bg-white {
    padding: 12px 15px;
  }
  .preview {
    margin-right: 5px;
  }
  .attach-style {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .fullinput .col-3 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .attach-style-proof {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 15px;
  }
  .fullspace.pandingfinsh {
    width: 100%;
    /* max-width: 300px; */
  }
  .Wholesale__Investor__Conetnt,
  .wholesale_content_header {
    padding: 15px;
  }
  .AGREEMENT-left {
    width: 100%;
  }
  .AGREEMENT-center {
    width: 100%;
  }
  /* .upper-space-table .behalf-sign {
      width: 500px;
  } */
  td.term-entity {
    width: auto !important;
  }
  .main-wrapper-AGREEMENT {
    flex-wrap: wrap;
  }
  .upper-space-table .behalf-sign td {
    border: 0;
    display: inline-block;
  }
  .second-EXECUTED-signature {
    flex-direction: column;
    width: 50%;
  }
  .second-EXECUTED-signature p {
    width: 100%;
    padding-left: 0;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 0px;
    /* margin-right: 10px; */
    word-break: break-all;
  }
  .box-EXECUTED-signature.EXECUTED-content_top_cnt,
  .box-EXECUTED-signature {
    align-items: flex-start;
    /* flex-wrap: nowrap; */
    flex-direction: row !important;
    margin-left: 0;
  }
  .first-EXECUTED-signature {
    flex-direction: column;
    width: 50%;
  }
  .EXECUTED-content_top_cnt .round-EXECUTED {
    width: 100%;
    margin-top: 15px;
  }
  ol.ORDER-Content {
    padding-left: 0 !important;
    padding-inline-start: 0;
  }
  .review_b p {
    padding-left: 0;
  }
  .outer-style-AGREEMENT {
    font-size: 14px;
  }
  .button_group.pos {
    position: unset;
    left: 0;
    margin: 15px 0;
  }
  /* trust responsive  */

  .fullinput .quantity_Trust {
    width: 100% !important;
  }
  .Trust_manual_btn.col .trus_address1 {
    width: 100%;
  }
  .trust-btn_div .trust_p {
    padding-left: 0;
    margin-top: 15px;
  }
  .trust-btn_div {
    flex-wrap: wrap;
  }
  .input_text_area textarea {
    width: 100%;
  }
  .trust_form {
    flex-wrap: wrap;
  }
  .trust_form .abcd {
    width: 100% !important;
  }
  .trust_form .abcd .abc {
    width: 100%;
  }
  .abcd + .switch-field.nospace {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  .abcd + .switch-field.nospace p.please {
    padding-bottom: 0;
    padding-right: 15px;
  }
  .ORDER-Content li {
    padding-left: 0;
  }
  .tenjs .col-md-12 {
    order: 2;
  }
  .review_b p {
    word-break: break-word;
  }
  .EXECUTED-content_top_cnt {
    margin: 0;
  }

  .jointtwelves .col-8 {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
  .jointtwelves .col-4 {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    margin-bottom: 15px;
  }
  /* trust responsive  */
}
@media screen and (max-width: 576px) {
}
@media screen and (max-width: 430px) {
  .review_b {
    flex-wrap: wrap;
  }
  .oops {
    font-size: 14px !important;
  }
  .review_b label,
  .review_b p {
    width: 100%;
  }
  .detail_btn .preview,
  .detail_btn .next {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .detail_btn .next {
    margin-top: 15px;
  }
  .detail_btn {
    flex-wrap: wrap;
  }
  .fullspace.pandingfinsh {
    width: 100%;
    /* max-width: 235px; */
  }
  .confirm_continue {
    flex-wrap: wrap;
  }
  .confirm_continue .preview,
  .confirm_continue .next {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .confirm_continue .next {
    margin-top: 15px;
  }
  .check-mb {
    flex-direction: column;
  }
  .swtich11 {
    width: 122.19px;
    height: 45px;
    /* margin-left: 20px; */
  }
  .tenjs .fotter-btn {
    flex-wrap: wrap;
  }

  .tenjs .fotter-btn button {
    width: 100% !important;
    margin: 15px 0 0px;
  }
  .PARTNER-info tr td {
    width: 50% !important;
  }
}
@media screen and (max-width: 320px) {
 
  .fullinputNumber .dropdown-mobile {
    width: 100%;
    max-width: 100%;
    padding-right: 20px !important;
  }
  .fullspace.pandingfinsh {
    width: 100%;
    /* max-width: 188px;
      padding-right: 12px; */
  }
}
/* trusted css */

.fullinput.fullinputNumber {
  margin-bottom: 15px;
  /* justify-content: space-between; */
}

.ALIGNTEXTTT {
  width: 100%;
  word-break: break-word;
}
.terms_cond_main_mr_top {
  margin-top: 66px;
}

@media screen and (min-width: 992px) {
  .fullinputNumber.select_num_add .col-md-6 {
    max-width: 58%;
    -ms-flex: 0 0 58%;
    flex: 0 0 58%;
  }
}
@media screen and (min-width: 768px) {
  .fullinputNumber.select_num_add .col-md-6 {
    max-width: 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
  }
}

img.info_address {
  width: 19px;
  font-size: 11px;
  cursor: pointer;
  /* filter: invert(1); */
  transition: 0.9s;
  display: inline-block;
}

.info_address:hover {
  transform: scale(1.2);
}

.ORDER-Content_number {
  display: inline-block;
  width: 3%;
  border: 2px solid var(--border-color);
}

/**li.LIST__CONSIDERATION {
    /* border: 2px solid var(--border-color);
    width: 90%;
    display: inline-block;
    vertical-align: text-top;
}


.main_wrapper_margin{
  margin: 3px 25px;
}

/* .main-second-text-content_adherance >ol >li li:before {
  content: counters(item, '.') ' ';
} */

.veri_btn .disable {
  display: flex;
  /* color: gray !important; */
  border-color: var(--title-color) !important;
  background-color: var(--title-bg_color);
}

.font-fami {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
}
.oops {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.swal2-popup {
  top: 50px;
}
.remove-margin {
  margin-bottom: 0 !important;
}

/* .registered.registered_input input::placeholder {
  text-transform: initial !important;
} */

input::placeholder {
  text-transform: initial !important;
}

.quantity input::placeholder {
  text-transform: initial !important;
}
.quantity {
  text-transform: capitalize;
  padding-left: 3px !important;
}
 
select.Selectgreay {
  color: grey !important;
}
select.greay,
select.undefinedgreay,
select.nullgreay,
select.greay {
  color: grey !important;
}
li.order-list-item::marker {
  font-weight: 600;
}
.entitytextarea {
  border: 1px solid var(--border-color);
  width: -webkit-fill-available;
  resize: none;
}
.myinfo {
  /* right: 118px;
  right: 118px; */
  position: unset;
  /* align-items: center; */
  /* display: flex; */
  display: inline-block;
  margin-left: 10px;
  float: unset;
}

.attach-style-proof label .myinfo {
  right: 175px !important;
}
.button_group.space-bottom {
  margin-bottom: 20px;
}
label.select {
  float: right;
}
p.round-EXECUTED.paddingNone {
  margin: 3px;
}
.Lorem-Ipsum{
  font-size: 16px;
  font-weight: 400;
}
.numberss1{
  width: 100% !important;
}
.entitytextareas {
  /* width: 650px; */
  height: 260px;
  font-size: 16px;
    font-weight: 500;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
  border: 1px solid #dee2e6;
}
span.error.ticks {
  margin-top: 2rem;
  margin-left: 14px
}
.blackkk button.swal2-close {
  font-size: 25px!important;
  margin-right: 15px;
  margin-top: -2px;
}
.blackkk  .swal2-close:hover {
  color: #FFF!important;
}
.blackkk  .swal2-close:focus {
  outline: 0;
  box-shadow: none;
}
.infobanks {
  width: 19px;
  float: right;
  position: absolute;
  bottom: 50px;
  font-size: 11px;
  right:30px;
  top: 8px;
  cursor: pointer;
  /* filter: invert(1); */
  transition: 0.9s;
}
.fotter-btn .next, .fotter-btn .preview, .fotter-btn  button{
position: relative;

}
.fotter-btn .next:after, .fotter-btn  a::after, .loginbuttton button::after {
  content: "\f105" !important;
  font-family: 'Font Awesome 5 Pro';
  right: 17px !important;
  left: inherit !important;
}
.fotter-btn .preview:after, .fotter-btn .next:after,
 .fotter-btn .loginbuttton button::after {
  position: absolute;
  content: "\f104" ;
  left: 17px;
  top: 11px;
  font-family: 'Font Awesome 5 Pro';
  font-size: 14px;
}
.fotter-btn .preview .leftarrow, .fotter-btn .next .rightarrow,
 .fotter-btn .loginbuttton img, .fotter-btn img {
  opacity: 0;
}
.account_info {
  margin-top: 5px;
  /* margin-right: -10px; */
}
.attach-style-proof img.info {
  right: -20px;
  top: 23px;
}
.swal2-popup.swal2-modal.swal2-show a {
  text-decoration: underline;
}
button.border_trans_btn {
  margin-bottom: 20px;
}
.switch_botton.hjj {
  margin-left: auto;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover{
background-image:unset !important;
}
/* .attach {
  margin-left: 15px;
} */
.indivial .attach {
  margin-left: 0;
}
div#swal2-html-container {
  padding-top: 20px;
}

div#swal2-html-container p {
  margin-bottom: 0;
  margin-top: 10px;
  text-align: center;
}
span.error.enterPhone {
  float: left;
  width: 100%;
}
span.error.sumbitCodeError {
    float: right;
    width: 100%;
    text-align: right;
    margin-top: 10px;
}
.swal-footer{
text-align: center !important;
}
img.info.backi {
    position: initial;
    top: m;
    margin-left: 25px;
}
.yes.backbt {
  display: flex;
  align-items: center;
  margin-top: 10px !important;
}

.btn_upload.bacnkbtn {
  cursor: pointer;
  /* position: relative; */
  position: relative;
  padding: 12px 22px;
}
button.uploadBtn {
  padding: 0;
}
.change_option_title {
  list-style: none;
}
.change_option_title b {
  color: #282829;
}
.change_option_title li {
  margin: 5px 0;
}
.ui.segment.clndr-calendars-segment.clndr-floating.clndr-left {
  display: none;
}
.resgist_popup div#swal2-html-container p {
  margin: 0;
  line-height: 24px;
}

p.readMoreTarget {
  margin-top: 8px;
  opacity: 0;
}
.note-main-loader {
  position: relative;
}
.note-main-loader .loader {
  top: -47px;
  left: 100px;
  right: 0;
  /* margin: auto; */
  width: 50px;
  height: 50px;
  /* border: 4px solid #F3F3F3;
  border-top: 4px solid var(--title-color) 000; */
  border: 8px solid var(--title-bg_color);
  border-top: 8px solid var(--border-color);
}
.post{
  text-transform: none !important;
}
.Please_attach{
  justify-content: flex-start ;
}
.termLink{
  color: #966A4D;
  text-decoration: underline !important;
}
.termLink:hover{
  color: #966A4D !important; 
}

.invsta_logo img {
  width: 100%;
  max-width: 120px;
  margin-left: auto;
}
.invsta_logo_reg img{
  width: 100%;
  max-width: 120px;
  margin-bottom: -2.5rem;
  margin-right: -1rem;
}
.invsta_logo_reg {
  text-align: right;
  width: 100%;
  display: inline-block;
}
.invsta_logo{
text-align: right;
width: 100%;
display: inline-block;
position: fixed;
bottom: 20px;
right: 20px;
}


.terms_notes {padding-left: 15px;}
.terms_notes  h1 {
    font-size: 24px;
    color: #282829 !important;
    margin-top: 20px;
}
.terms_notes  h5 {
    font-size: 18px;
    color: #000 !important;
    font-weight: 400;
}
.cont_list ul li {
    font-weight: 400;
    line-height: 26px;
}
.Privacy_Policy p {
  font-weight: 400;
}
.Privacy_Policy p a, .terms_notes h5 a {
  color:var(--title-bg_color);
}
.cont_list ul {
    margin-left: 20px;
}
.uploaded_btn .btn_custom {
  padding-left: 15px;
  padding-right: 14px;
}

.uploaded_btn .inform_identity_informbutton {
  margin-left: 0;
}
@media screen and (max-width:340px) {
  .uploaded_btn .btn_custom{
    max-width: 230px;
  }
}


.term_card p.lbe1 {
  min-width: 193px;
  /* text-align: right; */
  margin-right: 10px;
  /* margin-bottom: 0; */
}
.term_card {
  display: flex;
  flex-wrap: wrap;
}
.term_card p {
  margin-bottom: 3px;
}
.linkerror{
  bottom: 0;
  font-family: 'Roboto';
}


label.containers.font-fami {
  float: left;
}
.error.linkerror {
  float: left;
}

 span.herClas {
  font-size: 16px;
  font-family: 'Roboto', sans-serif !important;
}

span.error.errorghgh {
  margin-top: 17px;

  width: 100%;
  display: table;
}

.termstt {
  text-transform: capitalize;
  font-size: 16px;
}


 .trustSix {
  background: transparent;
  color: #282829 !important;
  border: 0;
  font-size: 11px !important;
  padding: 5px 25px;
  border-bottom: 1px solid gray;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: left;
  justify-content: flex-start;
  position: relative;
  max-width: 400px;

}
.address_data .row + div {
  overflow: auto;
  margin-bottom: 15px;
  max-width: 400px;
  box-shadow: 0 0 3px 0 grey;
}
 .trustSix:before {
  content: "\f3c5";
  position: absolute;
  left: 8px;
    top: 5px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #A3A3A3;
  font-size: 12px;
}

.check_btn {
    max-width: 400px;
    box-shadow: 0 0 3px 0 grey;
}
.google_add input {
  max-width: 410px;
  
  width: 100%;
}

.check_btn  button.trustSix {
  text-transform: initial!important;
}